var loader;
var scene;
var camera;
var referncePoint = new THREE.Vector2(0, 0);
var height = 50;
var leftAngle = 0;
var leftAngleInradian = convertDegreeToRadian(leftAngle);
var renderer;
var solarAngle = 0;
var directional_light = new THREE.DirectionalLight(0xfdfbd3 ,1.1);
var frameAnimation;
///var helper = undefined;
var plane = null
var compassplane = null
var angleTest = 0;
var sceneObject = new THREE.Object3D();
var dateAndTimeLabel;
var cameraHeight;
var facingCamera;
var dim0 ;
var dim1 ;
var helper = undefined;
var dragControls ;
var dragObject = [];
var captureViewComplete=false;
var viewimageData={};
var planemap =null;
var vectorArray = [];
var solarArray = [];
var roofRotationAxisVector = [];
var roofPoint = [];
var compassPlanematerial;
var materialmap;
// let solarObject3D = new THREE.Object3D();
// let panelObject3D = new THREE.Object3D();
// let houseObject3D = new THREE.Object3D();
////init();
//animate();

function timechange(e){
	//alert(e.target.value);
	//var str = e.target.value.val();
	var d = new Date(e.target.value);
	//console.log(d)
	//var solarposition = solarCalculator([12.7822,77.6585]).position(d)
	//console.log(solarposition)
	//setSolar(solarposition[0],solarposition[1])
	calculateSolarPosition(d)
  }


function calculateSolarPosition(userPickedDate){
	var lat = constant.LAT
	var lon = constant.LON
	var utcOffset = constant.UTCOFFSET
	var month = userPickedDate.getMonth() + 1;
	var year =  userPickedDate.getFullYear()
	var day = userPickedDate.getDate()
	var hr = userPickedDate.getHours()
	var mi =   userPickedDate.getMinutes()
	var sec = userPickedDate.getSeconds()
	var mins = hr*60 + mi  + sec /60.0
	var jday = getJD(year, month , day);
	var total = jday + mins/1440.0 - utcOffset/24.0
	var T = calcTimeJulianCent(total)
	var azel = calcAzEl(T, mins, lat, lon, utcOffset)
	var solnoon = calcSolNoon(jday, lon, utcOffset)
	var rise = calcSunriseSet(1, jday, lat, lon, utcOffset)
	var set  = calcSunriseSet(0, jday, lat,lon, utcOffset)

	var eqTime = calcEquationOfTime(T)
	setSolar(azel.azimuth, azel.elevation)
}

function init() {

	sceneObject = new THREE.Object3D();
	const canvas1 = document.getElementById("artifactCanvas");
	camera = new THREE.PerspectiveCamera(constant.CAMERA_FOV, constant.CANVAS_WIDTH / constant.CANVAS_HEIGHT, constant.CAMERA_NEAR, constant.CAMERA_FAR);
	//camera = new THREE.OrthographicCamera(-10000, 10000, 10000, -10000, 0.1, 10000)
	camera.position.z = constant.CAMERA_Z;
	camera.position.y = constant.CAMERA_Y;
	scene = new THREE.Scene();
	scene.add(sceneObject);
	loader = new THREE.TextureLoader();
	renderer = new THREE.WebGLRenderer({ canvas: canvas1, antialias :true, logarithmicDepthBuffer : true, preserveDrawingBuffer : true  });
	renderer.setSize(constant.CANVAS_WIDTH, constant.CANVAS_HEIGHT);
	//renderer.setClearColor(0x87ceeb);

	scene.background = new THREE.Color( 0x87ceeb );
	scene.fog = new THREE.Fog( 0x87ceeb, 2000, 3500 );
	renderer.shadowMap.enabled = true;
	renderer.shadowMap.type = THREE.PCFSoftShadowMap
	var ambient_light = new THREE.AmbientLight(0xffffff,0.5); // soft white light
	ambient_light.name = "light";
	ambient_light.position.y = 1500
	scene.add(ambient_light);
	directional_light.name = "Dir_light";
	directional_light.shadow.mapSize.width = 4096
	directional_light.shadow.mapSize.height = 4096
	directional_light.castShadow = true;   //Enable
	directional_light.shadow.camera.left = -1500;
	directional_light.shadow.camera.right = 1500;
	directional_light.shadow.camera.bottom = -1500;
	directional_light.shadow.camera.top = 1500;
	directional_light.shadow.camera.near = 10;
	directional_light.shadow.camera.far = 1000;

	directional_light.shadow.bias =  -0.01;
	//controls.update() must be called after any manual changes to the camera's transform               VIMP
	controls = new THREE.OrbitControls(camera, renderer.domElement); // TrackballControls  s

	controls.autoRotate = false
	//controls.minAzimuthAngle = 0
 	//controls.maxAzimuthAngle = Math.PI / 2
	controls.minPolarAngle = 0
	controls.maxPolarAngle = Math.PI/2
	controls.autoRotateSpeed = 3;
	controls.addEventListener('start', () => controls.autoRotate = false)
	//controls.addEventListener('onClick', () => controls.autoRotate = false)

	//controls = new THREE.TrackballControls(camera, renderer.domElement)
	let geometry = new THREE.PlaneGeometry( constant.GROUND_PLANE_WIDTH, constant.GROUND_PLANE_HEIGHT);
	const material = new THREE.MeshPhysicalMaterial({ color: 0xFFFFFF, side: THREE.DoubleSide });
	//material.map = loader.load('https://threejs.org/examples/textures/terrain/grasslight-big.jpg');
	//material.map = loader.load('image/grass.png');
	plane = new THREE.Mesh(geometry, material);
	plane.receiveShadow = true;
	plane.name = "Plane"
	let geometry1 = new THREE.PlaneGeometry( 10000,10000);  // 0x90EE90
	const material1 = new THREE.MeshPhysicalMaterial({ color: 0x6a7b59, side: THREE.DoubleSide });
	var b_plane = new THREE.Mesh(geometry1, material1);
	const size = 10000;
	const divisions = 100;
    const gridHelper = new THREE.GridHelper( size, divisions );
	gridHelper.position.y = -2
    scene.add( gridHelper );
	//b_plane.receiveShadow = true;
	b_plane.name = "B_Plane";
	b_plane.position.z = -5;
	let compasPlanegeometry = new THREE.PlaneGeometry( constant.COMPASSWIDTH, constant.COMPASSHEIGHT);
	 compassPlanematerial = new THREE.MeshPhysicalMaterial({ color: 0xFFFFFF, side: THREE.DoubleSide, transparent: true });
	//compassPlanematerial.map = loader.load('https://threejs.org/examples/textures/terrain/grasslight-big.jpg');
	//compassPlanematerial.map = loader.load('image/compass.jpg');
	compassPlanematerial.map = loader.load('../assets/images/compass2.png');
	compassplane = new THREE.Mesh(compasPlanegeometry, compassPlanematerial);
	compassplane.position.z = 3
	//compassplane.receiveShadow = true;
	compassplane.name = "compassplane"
	sceneObject.add(compassplane);
	//sceneObject.add(plane);
	sceneObject.add(b_plane);
	sceneObject.rotation.x = -Math.PI / 2;
}

function setSolar (angle1 , angle2){ //Theta //Fi
	//console.log(angle2)
	let radius = 500;
	angle1 = 90 - angle1;
	let angleInDegree1 = angle1 * (Math.PI / 180);
	let angleInDegree2 = angle2 * (Math.PI / 180);
	let x = (radius * (Math.cos(angleInDegree2))) * (Math.cos(angleInDegree1));
	let z = (radius * (Math.cos(angleInDegree2))) * (Math.sin(angleInDegree1));
	let y = radius * (Math.sin(angleInDegree2))
	directional_light.position.set(x,y,-z);
}



function createTree(position_x, position_y ,radius, treeHeight) {
	let treeobject = new THREE.Object3D()
	treeobject.name = "tree";
	const geometry = new THREE.CylinderGeometry(5, 5, treeHeight, radius);
	const material = new THREE.MeshPhysicalMaterial({ color: 0xF3760E, side: THREE.DoubleSide });
	const cylinder = new THREE.Mesh(geometry, material);
	cylinder.castShadow = true; //default is false
	treeobject.add(cylinder);

	const geometry1 = new THREE.SphereGeometry(radius,32,32);
	const material1 = new THREE.MeshPhysicalMaterial({ color: 0x20F30E, side: THREE.DoubleSide });
	const sphere = new THREE.Mesh(geometry1, material1);
	sphere.castShadow = true; //default is false
	//sphere.receiveShadow = true; //default.
	sphere.position.y = treeHeight / 2;

	treeobject.add(sphere);

	if (referncePoint.x == 0 & referncePoint.y == 0){
		treeobject.position.y = 0
		treeobject.position.x = 0
	}else{
		treeobject.position.x =  position_x - referncePoint.x
		treeobject.position.y =  referncePoint.y - position_y
	}
	treeobject.position.z = treeHeight/2
	treeobject.rotation.x = Math.PI / 2;
	sceneObject.add(treeobject);

}


function createHouseFromArray(path, roofHeight , roofTiltAngle, solarPanelAngle,parapetHeight, id) {
	vectorArray = [];
	// Path from JSON object
	let jsonVecArray = path;
	let houseObject3D = new THREE.Object3D(); // houseObject3D = new THREE.Object3D();
	let roofObject3D = new THREE.Object3D();
	houseObject3D.name = id
	roofObject3D.name = id

	// Adding Z value as Zero and putiing it in single array
	for (let i = 0; i < jsonVecArray.length; i++) {
		let data = jsonVecArray[i];
		vectorArray.push(data.x)
		vectorArray.push(data.y)
		vectorArray.push(0);
	}

	// Moving to local co-ordinate system where point 1 is considered as origin
	for (let i = 0; i < (vectorArray.length / 3); i++) {
		vectorArray[i * 3 + 0] = vectorArray[i * 3 + 0] - referncePoint.x;
		vectorArray[i * 3 + 1] = vectorArray[i * 3 + 1] - referncePoint.y;
	}

	// Calculating roof rotation axis vector
	let roofRotationAxisVector = new THREE.Vector3( vectorArray[3] - vectorArray[0], -(vectorArray[4] - vectorArray[1]), vectorArray[5] - vectorArray[2] );
	roofRotationAxisVector.normalize ()
	// Calculating roof Rotation Point through which rotation axis passes
	let roofPoint = new THREE.Vector3( vectorArray[0], -vectorArray[1],  roofHeight ); //  vectorArray[2] );

			sceneObject.add(houseObject3D)
			scene.add(roofObject3D)
			const material_Lroof = new THREE.MeshStandardMaterial({ color: 0x808080, side: THREE.DoubleSide });
			material_Lroof.transparent = true;
			material_Lroof.opacity = 1.0 //0.1;
			let updatedArray = [];
    	let target = new THREE.Vector3(1,1,1);
		let plane = new THREE.Plane();
		const p_geometry = new THREE.PlaneGeometry( 1000, 1000 );
		const p_material = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
		const p_plane = new THREE.Mesh( p_geometry, p_material );
		p_plane.name = id
		p_plane.position.z = roofHeight
		rotateAroundWorldAxis(p_plane,roofPoint,roofRotationAxisVector, roofTiltAngle)
		sceneObject.add(p_plane);
		p_plane.updateMatrixWorld();
		//p_plane.material.needsUpdate = true
		//p_plane.material.transparent = true
		//p_plane.material.opacity=1;
		let v11 = p_plane.localToWorld(new THREE.Vector3(p_plane.geometry.attributes.position.array[0],p_plane.geometry.attributes.position.array[1],p_plane.geometry.attributes.position.array[2]))
		let v22 = p_plane.localToWorld(new THREE.Vector3(p_plane.geometry.attributes.position.array[3],p_plane.geometry.attributes.position.array[4],p_plane.geometry.attributes.position.array[5]))
		let v33 = p_plane.localToWorld(new THREE.Vector3(p_plane.geometry.attributes.position.array[6],p_plane.geometry.attributes.position.array[7],p_plane.geometry.attributes.position.array[8]))
		plane.setFromCoplanarPoints ( v11,v22,v33);




			let tmpArray = []
			//updatedArray = [];
		for (let j=0;j< vectorArray.length/3-1;j++){
			// Using Ray to calculate intersection point with plane
			let firstPointOfDormer = new THREE.Vector3( vectorArray[j*3+0], 0, vectorArray[j*3+1]);
			let yAxisVector = new THREE.Vector3(0,1,0);
			var yAxisRay = new THREE.Ray(firstPointOfDormer, yAxisVector);
			var planeIntersectionPoint = yAxisRay.intersectPlane(plane, target);
			var dis = yAxisRay.distanceToPlane(plane);
			tmpArray.push(target.clone())
		}
		let spherObject3D = new THREE.Object3D();
		scene.add(spherObject3D)
		for (let i=0 ; i<tmpArray.length;i++){
			const geometry = new THREE.SphereGeometry( 10, 32, 16 );
			const material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
			const sphere = new THREE.Mesh( geometry, material );
			let pos = p_plane.worldToLocal(new THREE.Vector3(tmpArray[i].x,tmpArray[i].y,tmpArray[i].z ))
			//console.log(pos)
			sphere.position.x = pos.x
			sphere.position.y =  pos.y
			sphere.position.z =  pos.z
			p_plane.add( sphere );
		}
		rotateAroundWorldAxis(p_plane,roofPoint,roofRotationAxisVector, -roofTiltAngle)
		p_plane.updateMatrixWorld();


		let FinalArrayPt = []
		for (let i=0 ; i<tmpArray.length;i++){
			let v1134 = p_plane.localToWorld(p_plane.children[i].position)
			FinalArrayPt.push(v1134)
		}
		let minHeight = 10000;
		for (let i=0 ; i<tmpArray.length;i++){
			if (tmpArray[i].y < minHeight) {
				minHeight = tmpArray[i].y
			}
		}

		sceneObject.remove(p_plane)
		houseObject3D.userData = { v1: v11 , v2 : v22, v3 : v33 , minHeight : minHeight};
		let mesh;
		FinalArrayPt.push(FinalArrayPt[0].clone())
		{
			const polygon1 = new THREE.Shape();
			let i;
			for (i = 0; i < (FinalArrayPt.length-1); i++) {
				if (i == 0) {
					polygon1.moveTo( FinalArrayPt[i].x, -FinalArrayPt[i].z );
				} else {
					polygon1.lineTo(FinalArrayPt[i].x, -FinalArrayPt[i].z);
				}
			}
			const geometry_new = new THREE.ShapeGeometry(polygon1);  //0x808080
			const material_Lroof = new THREE.MeshStandardMaterial({ color: 0xFFFF0, side: THREE.DoubleSide });
			const mesh_roof = new THREE.Mesh(geometry_new, material_Lroof);
			mesh = mesh_roof
			mesh_roof.name = "LeftMesh";
			material_Lroof.transparent = true;
			material_Lroof.opacity = 1.0 //0.1;
			//material_Lroof.map = loader.load('image/roof.JPG');
			mesh_roof.receiveShadow = true;
			mesh_roof.castShadow = true;    //Material
			houseObject3D.add(mesh_roof);
			mesh_roof.position.z =  roofHeight //height;
			rotateAroundWorldAxis(mesh_roof,roofPoint,roofRotationAxisVector, roofTiltAngle)

			let object = mesh_roof;
			object.updateMatrixWorld();
			let updatedArrayN = [];
			updatedArray = []

			// this code is for version r128
			for (let j = 0; j < object.geometry.parameters.shapes.curves.length; j++) {
				let vector =  new THREE.Vector3(object.geometry.parameters.shapes.curves[j].v1.x, object.geometry.parameters.shapes.curves[j].v1.y , 0.0 )//object.geometry.vertices[j].clone();
				vector.applyMatrix4(object.matrixWorld);
				updatedArrayN.push(vector)
				updatedArray.push(vector)
			}
		}
			tmpArray.push(tmpArray[0].clone());

			//rotateAroundWorldAxis(p_plane,roofPoint,roofRotationAxisVector, roofTiltAngle)

			for (i = 0; i <  tmpArray.length - 1 ; i++) {
				//let v1 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y, updatedArray[i].z);
				//let v2 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y , 0);
				//let v3 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, 0);
				//let v4 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, updatedArray[i+1].z);
				let v1 = new THREE.Vector3(tmpArray[i].x, tmpArray[i].y, tmpArray[i].z);
				let v2 = new THREE.Vector3(tmpArray[i].x, 0 , tmpArray[i].z);
				let v3 = new THREE.Vector3(tmpArray[i+1].x, 0, tmpArray[i+1].z);
				let v4 = new THREE.Vector3(tmpArray[i+1].x, tmpArray[i+1].y, tmpArray[i+1].z);
				let mesh1 = drawPolygon(v1, v2, v3, v4)
				mesh1.name = "wall";
				mesh1.castShadow = true; //
				mesh1.receiveShadow = true;
				roofObject3D.add(mesh1);
				//houseObject3D.add(mesh1)
				let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
				edges.material.linewidth = 2;
				mesh1.add(edges);
			}
			if (parapetHeight != 0) {
				let object = mesh;
				object.updateMatrixWorld();
				let newUpdatedArray = [];
				//r128
				for (let j = 0; j < object.geometry.parameters.shapes.curves.length; j++) {
					let vector =  new THREE.Vector3(object.geometry.parameters.shapes.curves[j].v1.x, object.geometry.parameters.shapes.curves[j].v1.y , 0.0 )//object.geometry.vertices[j].clone();
					vector.applyMatrix4(object.matrixWorld);
					newUpdatedArray.push(vector)
				}

				for (i = 0; i < newUpdatedArray.length ; i++) {
					let v1 = new THREE.Vector3(newUpdatedArray[i].x, newUpdatedArray[i].y, newUpdatedArray[i].z);
					let v2 = new THREE.Vector3(newUpdatedArray[i].x, newUpdatedArray[i].y , roofHeight + parapetHeight);
					let v3 ;
					let v4;
					if (i == newUpdatedArray.length - 1) {
						 v3 = new THREE.Vector3(newUpdatedArray[0].x, newUpdatedArray[0].y, newUpdatedArray[i].z + parapetHeight);
						 v4 = new THREE.Vector3(newUpdatedArray[0].x, newUpdatedArray[0].y, newUpdatedArray[0].z);
					}else{
					 	v3 = new THREE.Vector3(newUpdatedArray[i+1].x, newUpdatedArray[i+1].y, newUpdatedArray[i].z + parapetHeight);
					 	v4 = new THREE.Vector3(newUpdatedArray[i+1].x, newUpdatedArray[i+1].y, newUpdatedArray[i+1].z);
					}
					let mesh1 = drawPolygon(v1, v2, v3, v4)
					mesh1.name = "parafet";
					mesh1.castShadow = true; //
					mesh1.receiveShadow = true;
					houseObject3D.add(mesh1);
					let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
					edges.material.linewidth = 2;
					mesh1.add(edges);
				}

			}

	return houseObject3D;
}

function createKeepOut(keepouts, outerMesh, height, actualHeight, roofId){
		let solarObject = keepouts
		for (let i = 0; i < 1 /* keepouts.length*/; i++) {
			let keepouts3D = new THREE.Object3D();
			keepouts3D.name = "keepout"
			solarArray = [];
			let solarData = keepouts.path;
			for (let j = 0; j < solarData.length-1; j++) {
				let data = solarData[j];
				solarArray.push(+(data.x));
				solarArray.push(+(data.y));
			}
			for (let k = 0; k < (solarArray.length / 2); k++) {
				solarArray[k * 2 + 0] = solarArray[k * 2 + 0] - referncePoint.x;
				solarArray[k * 2 + 1] = solarArray[k * 2 + 1] - referncePoint.y;
			}

			let obj = sceneObject.getChildByName(keepouts.roofId);
			let minHeight = 1000
			let maxHeight = -1000
			if (obj) {

				let V1 = obj.userData.v1
				let V2 = obj.userData.v2
				let V3 = obj.userData.v3
				var plane = new THREE.Plane();
				plane.setFromCoplanarPoints ( V1, V2,V3);
				for (let j=0 ; j <solarArray.length/2 ; j++) {
					let firstPointOfDormer = new THREE.Vector3( solarArray[j*2+0], 0, solarArray[j*2+1]);
    				let target = new THREE.Vector3(1,1,1);
					// Using Ray to calculate intersection point with plane
					let yAxisVector = new THREE.Vector3(0,1,0);
					var yAxisRay = new THREE.Ray(firstPointOfDormer, yAxisVector);
					var planeIntersectionPoint = yAxisRay.intersectPlane(plane, target);
					var dis = yAxisRay.distanceToPlane(plane);
					//if (dis < minHeight){
						//minHeight = dis
					//}
					if (dis > maxHeight){
						maxHeight = dis
					}
					console.log(dis)

				}
			}
			 //console.log(minHeight)

			if (i == 0){
				diff_solar = solarArray[3] - solarArray[1];
			}
			var m = scene.getObjectByName("LeftMesh");
			if (m != undefined) {
				const polygon = new THREE.Shape();
				polygon.currentPoint.x = solarArray[0];
				polygon.currentPoint.y = -solarArray[1];
				for (let i = 0; i < ((solarArray.length / 2)); i++) {
					if (i == 1) {
						//polygon.moveTo( vectorArray[i*2 + 0], vectorArray[i*2 + 1] );
						polygon.lineTo(solarArray[i * 2 + 0], -solarArray[i * 2 + 1]);
					} else {
						polygon.lineTo(solarArray[i * 2 + 0], -solarArray[i * 2 + 1]);
					}
				}
				const geometry = new THREE.ShapeGeometry(polygon);
				const material_Lroof = new THREE.MeshPhysicalMaterial({ color: 0x808080, side: THREE.DoubleSide });
				material_Lroof.transparent = true;
				material_Lroof.opacity = 1.0 //0.7;
				const mesh = new THREE.Mesh(geometry, material_Lroof);
				mesh.name = "keepoutMesh";
				mesh.rotation.x =  0; //solarPanelAngle //solarAngle ; //0.32 //0.16//-Math.PI / 2;
				mesh.castShadow = true;
				var mesh2 = mesh.clone();

				//m.add(mesh);
				keepouts3D.add(mesh);
				keepouts3D.add(mesh2);
				outerMesh.add(keepouts3D);

				if (maxHeight == -1000){
					mesh.position.z = actualHeight //              height //-12.0;    //Change position
				}else{
					mesh.position.z =   maxHeight + actualHeight //              height //-12.0;    //Change position
				}

				mesh2.position.z = 0
				mesh2.name = "bottomkeepoutMesh"
				mesh2.castShadow = true;
				let object =  mesh //mesh2;
				object.updateMatrixWorld();
				let updatedArray = [];

				//r128
			for (let j = 0; j < object.geometry.parameters.shapes.curves.length; j++) {
				let vector =  new THREE.Vector3(object.geometry.parameters.shapes.curves[j].v1.x, object.geometry.parameters.shapes.curves[j].v1.y , 0.0 )//object.geometry.vertices[j].clone();
				vector.applyMatrix4(object.matrixWorld);
				updatedArray.push(vector)
			}
				updatedArray.push(updatedArray[0].clone());
				for (i = 0; i <  updatedArray.length - 1 ; i++) {
					let v1 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y, updatedArray[i].z);
					let v2 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y , 0);
					let v3 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, 0);
					let v4 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, updatedArray[i+1].z);
					let mesh1 = drawPolygon(v1, v2, v3, v4)
					mesh1.name = "wall";
					mesh1.castShadow = true; //
					mesh1.receiveShadow = true;
					keepouts3D.add(mesh1);
					let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
					edges.material.linewidth = 0.1;
					mesh1.add(edges);
				}

			}
	}
}

function createSolarFromArray(jsonObject, outerMesh ,solarPanelAngle, roofID) {
//   solarObject3D = new THREE.Object3D();
//   panelObject3D = new THREE.Object3D();
	let solarObject = jsonObject
	let obj = sceneObject.getChildByName(roofID);

	for (let i = 0; i < solarObject.length ; i++) {
		let solarObject3D = new THREE.Object3D();
		let panelObject3D = new THREE.Object3D();

		scene.add(panelObject3D)
		solarObject.name = "solarobject"
		solarArray = [];
		let solarData = solarObject[i].coords;
		for (let j = 0; j < solarData.length; j++) {
			let data = solarData[j];
			solarArray.push(+(data.x));
			solarArray.push(+(data.y));
		}
		for (let k = 0; k < (solarArray.length / 2); k++) {
			solarArray[k * 2 + 0] = solarArray[k * 2 + 0] - referncePoint.x;
			solarArray[k * 2 + 1] = solarArray[k * 2 + 1] - referncePoint.y;
		}

		// If Roof Angle is more than 0
		if (outerMesh.rotation._x != 0 &&  outerMesh.rotation._y != 0 && outerMesh.rotation._z != 0){
			if (obj) {
				let V1 = obj.userData.v1
				let V2 = obj.userData.v2
				let V3 = obj.userData.v3
				var plane = new THREE.Plane();
				plane.setFromCoplanarPoints ( V1, V2,V3);
				let tmpArray1 = []
				let tmpArray2 = []
				for (let j=0 ; j <solarArray.length/2 ; j++) {
					let firstPointOfDormer = new THREE.Vector3( solarArray[j*2+0], 0, solarArray[j*2+1]);
					let target = new THREE.Vector3(1,1,1);
					// Using Ray to calculate intersection point with plane
					let yAxisVector = new THREE.Vector3(0,1,0);
					var yAxisRay = new THREE.Ray(firstPointOfDormer, yAxisVector);
					var planeIntersectionPoint = yAxisRay.intersectPlane(plane, target);
					if(!planeIntersectionPoint){
						console.log("error in plane of interection:",planeIntersectionPoint)
						return;
					}
					//planeIntersectionPoint.y = planeIntersectionPoint.y + 0.5;
					tmpArray1.push(new THREE.Vector3( planeIntersectionPoint.x, planeIntersectionPoint.y + 2.0, planeIntersectionPoint.z));
					//planeIntersectionPoint.y = planeIntersectionPoint.y + 1.5;
					//var dis = yAxisRay.distanceToPlane(plane);
					tmpArray2.push(new THREE.Vector3( planeIntersectionPoint.x, planeIntersectionPoint.y+ 0.5, planeIntersectionPoint.z));
				}

				var originalNormal =  new THREE.Vector3(0,1,0)
				var objRotation = outerMesh.rotation;
				originalNormal.applyEuler(objRotation);
				var x_center = (tmpArray2[1].x + tmpArray2[2].x)/2.0
				var y_center = (tmpArray2[1].y + tmpArray2[2].y)/2.0
				var z_center = (tmpArray2[1].z + tmpArray2[2].z)/2.0
				var n_plane = new THREE.Plane()
				n_plane.setFromNormalAndCoplanarPoint (originalNormal, new THREE.Vector3(x_center,y_center,z_center));
				 var helper = new THREE.PlaneHelper( n_plane, 100, 0xffff00 );
				//panelObject3D.add( helper );
				const origin = new THREE.Vector3( x_center,y_center,z_center);
				const length = 10;
				const hex = 0xffff00;

				var axis = new THREE.Vector3(tmpArray2[2].x - x_center, tmpArray2[2].y - y_center, tmpArray2[2].z - z_center)
				const arrowHelper = new THREE.ArrowHelper( originalNormal, origin, length, hex );
				//panelObject3D.add( arrowHelper );
				const arrowHelper1 = new THREE.ArrowHelper( axis, origin, length, hex );
				//panelObject3D.add( arrowHelper1 );

				const color = new THREE.Color( 0x0000FF );
				var dispPlane = convertMathPlanetoPlaneGeometry(plane,color,new THREE.Vector3(x_center,y_center,z_center))
				//panelObject3D.add(dispPlane)
				rotateAroundWorldAxis(dispPlane,new THREE.Vector3(x_center,y_center,z_center),axis,solarPanelAngle)

				var newMathPlane = convertPlaneGeomToMathPlane(dispPlane,new THREE.Vector3(x_center,y_center,z_center) )
				tmpArray1 = []
				tmpArray2 = []
				for (let j=0 ; j <solarArray.length/2 ; j++) {
					let firstPointOfDormer = new THREE.Vector3( solarArray[j*2+0], 0, solarArray[j*2+1]);
					let target = new THREE.Vector3(1,1,1);
					// Using Ray to calculate intersection point with plane
					let yAxisVector = new THREE.Vector3(0,1,0);
					var yAxisRay = new THREE.Ray(firstPointOfDormer, yAxisVector);
					var planeIntersectionPoint = yAxisRay.intersectPlane(newMathPlane, target);
					//planeIntersectionPoint.y = planeIntersectionPoint.y + 0.5;
					//tmpArray1.push(new THREE.Vector3( planeIntersectionPoint.x, planeIntersectionPoint.y + 2.0, planeIntersectionPoint.z));
					//planeIntersectionPoint.y = planeIntersectionPoint.y + 1.5;
					//var dis = yAxisRay.distanceToPlane(plane);
					tmpArray2.push(new THREE.Vector3( planeIntersectionPoint.x, planeIntersectionPoint.y+ 0.2, planeIntersectionPoint.z));
				}

			/*	let mesh1 = drawPolygon(tmpArray1[0], tmpArray1[1], tmpArray1[2], tmpArray1[3])
				mesh1.material =  new THREE.MeshBasicMaterial({ color: 0x474490, side: THREE.DoubleSide });
				mesh1.name = "solarPanel";
				mesh1.castShadow = true; //
				mesh1.receiveShadow = true;
				let edges2 = new THREE.EdgesHelper(mesh1, 0xffffff);
				edges2.material.linewidth = 2;
				mesh1.add(edges2);
				panelObject3D.add(mesh1);*/
				let mesh2 = drawPolygon(tmpArray2[0], tmpArray2[1], tmpArray2[2], tmpArray2[3])
				mesh2.material =  new THREE.MeshBasicMaterial({ color: 0x474490, side: THREE.DoubleSide });
				mesh2.name = "solarPanel";
				mesh2.castShadow = true; //
				mesh2.receiveShadow = true;
				let edges1 = new THREE.EdgesHelper(mesh2, 0xffffff);
				edges1.material.linewidth = 2;
				mesh2.add(edges1);
				panelObject3D.add(mesh2);

				//Panel walls
			/*	let wall1 = drawPolygon(tmpArray1[0], tmpArray2[0], tmpArray2[1], tmpArray1[1])
				wall1.material =  new THREE.MeshBasicMaterial({ color: 0x474490, side: THREE.DoubleSide });
				wall1.name = "solarPanelWalls";
				wall1.castShadow = true; //
				wall1.receiveShadow = true;
				let wallEdge = new THREE.EdgesHelper(wall1, 0xffffff);
				wallEdge.material.linewidth = 2;
				wall1.add(wallEdge);
				panelObject3D.add(wall1);

				//Panel walls
				let wall2 = drawPolygon(tmpArray1[1], tmpArray2[1], tmpArray2[2], tmpArray1[2])
				wall2.material =  new THREE.MeshBasicMaterial({ color: 0x474490, side: THREE.DoubleSide });
				wall2.name = "solarPanelWalls";
				wall2.castShadow = true; //
				wall2.receiveShadow = true;
				let wallEdge2 = new THREE.EdgesHelper(wall2, 0xffffff);
				wallEdge2.material.linewidth = 2;
				wall2.add(wallEdge2);
				panelObject3D.add(wall2);

				//Panel walls
				let wall3 = drawPolygon(tmpArray1[2], tmpArray2[2], tmpArray2[3], tmpArray1[3])
				wall3.material =  new THREE.MeshBasicMaterial({ color: 0x474490, side: THREE.DoubleSide });
				wall3.name = "solarPanelWalls";
				wall3.castShadow = true; //
				wall3.receiveShadow = true;
				let wallEdge3 = new THREE.EdgesHelper(wall3, 0xffffff);
				wallEdge3.material.linewidth = 2;
				wall3.add(wallEdge3);
				panelObject3D.add(wall3);

				//Panel walls
				let wall4 = drawPolygon(tmpArray1[3], tmpArray2[3], tmpArray2[0], tmpArray1[0])
				wall4.material =  new THREE.MeshBasicMaterial({ color: 0x474490, side: THREE.DoubleSide });
				wall4.name = "solarPanelWalls";
				wall4.castShadow = true; //
				wall4.receiveShadow = true;
				let wallEdge4 = new THREE.EdgesHelper(wall4, 0xffffff);
				wallEdge4.material.linewidth = 2;
				wall4.add(wallEdge4);
				panelObject3D.add(wall4);

*/
			}
		}else{
			var m = scene.getObjectByName("LeftMesh");
			if (m != undefined) {
			const polygon = new THREE.Shape();
			polygon.currentPoint.x = solarArray[0];
			polygon.currentPoint.y = -solarArray[1];

			for (let j = 0; j < ((solarArray.length / 2)); j++) {
				if (j == 1) {
					polygon.moveTo(solarArray[j * 2 + 0], -solarArray[j * 2 + 1]);
				} else {
					polygon.lineTo(solarArray[j * 2 + 0], -solarArray[j * 2 + 1]);
				}
			}
			const geometry = new THREE.ShapeGeometry(polygon); //0x09143C
			const material_Lroof = new THREE.MeshPhysicalMaterial({ color: 0x474490 , side: THREE.DoubleSide });
			material_Lroof.transparent = true;
			material_Lroof.opacity = 1.0 //0.7;
			//material_Lroof.map = loader.load('image/pannel.JPG');
			const mesh = new THREE.Mesh(geometry, material_Lroof);
			mesh.name = "Solar";
			mesh.castShadow = true;
			mesh.receiveShadow = true
			var mesh2 = mesh.clone();
			//solarObject3D.add(mesh);
			solarObject3D.add(mesh2);
			outerMesh.add(solarObject3D);
			//sceneObject.add(solarObject3D);
			//mesh.position.z = 2.0;
			mesh2.position.z = 0.2;
			mesh2.name = "bottomSolar"
			mesh2.castShadow = true;
			mesh.receiveShadow = true

			// Calculating roof rotation axis vector
			let roofRotationAxisVector = new THREE.Vector3( solarArray[0] - solarArray[6], -(solarArray[1] - solarArray[7]), 0 );
			roofRotationAxisVector.normalize ()


			// Calculating roof Rotation Point through which rotation axis passes
			//let roofPoint = new THREE.Vector3( solarArray[0], -solarArray[1],  0 ); //  vectorArray[2] );
			let roofPoint = new THREE.Vector3( solarArray[2], -solarArray[3],  0 ); //  vectorArray[2] );
			rotateAroundWorldAxis(solarObject3D,roofPoint,roofRotationAxisVector, -solarPanelAngle)
			let edges = new THREE.EdgesHelper(mesh, 0xffffff);
			edges.material.linewidth = 4;
			mesh.add(edges);
			let edges2 = new THREE.EdgesHelper(mesh2, 0xffffff);
			edges2.material.linewidth = 4;
			mesh2.add(edges2);
			let object = mesh2;
			let y = 0;
			object.updateMatrixWorld();
			//r128
			for (let j = 0; j < object.geometry.parameters.shapes.curves.length; j++) {
				let vector =  new THREE.Vector3(object.geometry.parameters.shapes.curves[j].v1.x, object.geometry.parameters.shapes.curves[j].v1.y , 0.0 )//object.geometry.vertices[j].clone();
				vector.applyMatrix4(object.matrixWorld);
				if (j == 1){
					y =vector.z
				}
			}

			let sideMaterial = new THREE.MeshStandardMaterial({ color: 0x808080, side: THREE.DoubleSide });
			let o = mesh;
			let o1 = mesh2;
			o.updateMatrixWorld();
			o1.updateMatrixWorld();
		/*	let vector1 =  new THREE.Vector3(o.geometry.parameters.shapes.curves[2].v2.x,o.geometry.parameters.shapes.curves[2].v2.y,0)                      //o.geometry.vertices[0].clone();
			vector1.applyMatrix4(o.matrixWorld);
			let vector2 = new THREE.Vector3(o.geometry.parameters.shapes.curves[2].v1.x,o.geometry.parameters.shapes.curves[2].v1.y,0)    //o.geometry.vertices[1].clone();
			vector2.applyMatrix4(o.matrixWorld);
			let vector3 = new THREE.Vector3(o.geometry.parameters.shapes.curves[2].v2.x,o.geometry.parameters.shapes.curves[2].v2.y,0)  //o1.geometry.vertices[0].clone();
			vector3.applyMatrix4(o1.matrixWorld);
			let vector4 = new THREE.Vector3(o1.geometry.parameters.shapes.curves[2].v1.x,o1.geometry.parameters.shapes.curves[2].v1.y,0)  //o1.geometry.vertices[1].clone();
			vector4.applyMatrix4(o1.matrixWorld);
			let wall1 = drawPolygon(vector1, vector3, vector4, vector2)
			wall1.name = "solarwall";
			wall1.material = sideMaterial;
			edges = new THREE.EdgesHelper(wall1, 0xffffff);
			edges.material.linewidth = 2;
			wall1.add(edges);
			solarObject3D.add(wall1)


			vector1 =  new THREE.Vector3(o.geometry.parameters.shapes.curves[1].v2.x,o.geometry.parameters.shapes.curves[1].v2.y,0)                      //o.geometry.vertices[0].clone();
			vector1.applyMatrix4(o.matrixWorld);
			vector2 = new THREE.Vector3(o.geometry.parameters.shapes.curves[1].v1.x,o.geometry.parameters.shapes.curves[1].v1.y,0)    //o.geometry.vertices[1].clone();
			vector2.applyMatrix4(o.matrixWorld);
			vector3 = new THREE.Vector3(o.geometry.parameters.shapes.curves[1].v2.x,o.geometry.parameters.shapes.curves[1].v2.y,0)  //o1.geometry.vertices[0].clone();
			vector3.applyMatrix4(o1.matrixWorld);
			vector4 = new THREE.Vector3(o1.geometry.parameters.shapes.curves[1].v1.x,o1.geometry.parameters.shapes.curves[1].v1.y,0)  //o1.geometry.vertices[1].clone();
			vector4.applyMatrix4(o1.matrixWorld);
			let wall2 = drawPolygon(vector1, vector3, vector4, vector2)
			wall2.name = "solarwall";
			wall2.material = sideMaterial;
			edges = new THREE.EdgesHelper(wall2, 0xffffff);
			edges.material.linewidth = 2;
			wall2.add(edges);
			solarObject3D.add(wall2)

			vector1 =  new THREE.Vector3(o.geometry.parameters.shapes.curves[1].v1.x,o.geometry.parameters.shapes.curves[1].v1.y,0)                      //o.geometry.vertices[0].clone();
			vector1.applyMatrix4(o.matrixWorld);
			vector2 = new THREE.Vector3(o.geometry.parameters.shapes.curves[0].v1.x,o.geometry.parameters.shapes.curves[0].v1.y,0)    //o.geometry.vertices[1].clone();
			vector2.applyMatrix4(o.matrixWorld);
			vector3 = new THREE.Vector3(o.geometry.parameters.shapes.curves[1].v1.x,o.geometry.parameters.shapes.curves[1].v1.y,0)  //o1.geometry.vertices[0].clone();
			vector3.applyMatrix4(o1.matrixWorld);
			vector4 = new THREE.Vector3(o1.geometry.parameters.shapes.curves[0].v1.x,o1.geometry.parameters.shapes.curves[0].v1.y,0)  //o1.geometry.vertices[1].clone();
			vector4.applyMatrix4(o1.matrixWorld);
			let wall3 = drawPolygon(vector1, vector3, vector4, vector2)
			wall3.name = "solarwall";
			wall3.material = sideMaterial;
			edges = new THREE.EdgesHelper(wall3, 0xffffff);
			edges.material.linewidth = 2;
			wall3.add(edges);
			solarObject3D.add(wall3)
			vector1 =  new THREE.Vector3(o.geometry.parameters.shapes.curves[0].v1.x,o.geometry.parameters.shapes.curves[0].v1.y,0)                      //o.geometry.vertices[0].clone();
			vector1.applyMatrix4(o.matrixWorld);
			vector2 = new THREE.Vector3(o.geometry.parameters.shapes.curves[2].v2.x,o.geometry.parameters.shapes.curves[2].v2.y,0)    //o.geometry.vertices[1].clone();
			vector2.applyMatrix4(o.matrixWorld);
			vector3 = new THREE.Vector3(o.geometry.parameters.shapes.curves[0].v1.x,o.geometry.parameters.shapes.curves[0].v1.y,0)  //o1.geometry.vertices[0].clone();
			vector3.applyMatrix4(o1.matrixWorld);
			vector4 = new THREE.Vector3(o1.geometry.parameters.shapes.curves[2].v2.x,o1.geometry.parameters.shapes.curves[2].v2.y,0)  //o1.geometry.vertices[1].clone();
			vector4.applyMatrix4(o1.matrixWorld);
			let wall4 = drawPolygon(vector1, vector3, vector4, vector2)
			wall4.name = "solarwall";
			wall4.material = sideMaterial;
			edges = new THREE.EdgesHelper(wall4, 0xffffff);
			edges.material.linewidth = 2;
			wall4.add(edges);
			solarObject3D.add(wall4)*/
		}
		}


	}
}


function convertDegreeToRadian(degree) {
	return (degree * (Math.PI / 180));
}

function convertPlaneGeomToMathPlane(planeMesh,position)
{
	var plane = new THREE.Plane()
	var actualNormal = planeMesh.userData.nomral
	var originalNormal =  new THREE.Vector3(actualNormal.x, actualNormal.y , actualNormal.z)
	var objRotation = planeMesh.rotation;
	originalNormal.applyEuler(objRotation);
	plane.setFromNormalAndCoplanarPoint( originalNormal,position );
	return plane
}

function convertMathPlanetoPlaneGeometry(plane,color,position)
{
	var planeGeometry = new THREE.PlaneGeometry(50, 50);
	var coplanarPoint =  new THREE.Vector3(0,0,0);
	coplanarPoint = plane.normal.clone()
	coplanarPoint.multiplyScalar(-plane.constant);
	var focalPoint = new THREE.Vector3().copy(coplanarPoint).add(plane.normal);
	planeGeometry.lookAt(focalPoint);
	planeGeometry.translate(position.x, position.y, position.z);
	var planeMaterial = new THREE.MeshLambertMaterial({ color: 0xFF0000, side: THREE.DoubleSide, transparent :true, opacity : 1.0 });
	var dispPlane = new THREE.Mesh(planeGeometry, planeMaterial);
	const edges = new THREE.EdgesGeometry( planeGeometry );
	dispPlane.userData = {nomral : plane.normal.clone()}
	const line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: color } ) );
	dispPlane.add(line)
	return dispPlane
}




function drawMeshFromVertices(vertices_array){
	const geometry1 = new THREE.BufferGeometry();
	const vertices = new Float32Array(vertices_array.length*3)
	for (let i=0;i<vertices_array.length;i++){
		//vertices(vertices_array[i*3+0])
		//vertices.push(vertices_array[i*3+1])
		//vertices.push(vertices_array[i*3+2])
		vertices[i*3+0] = vertices_array[i].x;
		vertices[i*3+1] = vertices_array[i].y;
		vertices[i*3+2] = vertices_array[i].z;
	}
	/*
	const vertices = new Float32Array( [
		v1.x , v1.y , v1.z ,
		v2.x , v2.y , v2.z ,
		v3.x , v3.y , v3.z ,
		v1.x , v1.y , v1.z ,
		v3.x , v3.y , v3.z ,
		v4.x , v4.y , v4.z ,

	] );*/


	// itemSize = 3 because there are 3 values (components) per vertex
	geometry1.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
	//geometry1.computeVertexNormals();
	const material1 = new THREE.MeshBasicMaterial({ color: 0x808080, side: THREE.DoubleSide });
	const mesh = new THREE.Mesh( geometry1, material1 );
	//mesh.receiveShadow = true;
	//mesh.castShadow = true;
	return mesh;
}

function drawPolygon(v1, v2, v3, v4) {

	const geometry1 = new THREE.BufferGeometry();
	const vertices = new Float32Array( [
		v1.x , v1.y , v1.z ,
		v2.x , v2.y , v2.z ,
		v3.x , v3.y , v3.z ,
		v1.x , v1.y , v1.z ,
		v3.x , v3.y , v3.z ,
		v4.x , v4.y , v4.z ,

	] );


	// itemSize = 3 because there are 3 values (components) per vertex
	geometry1.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
	geometry1.computeVertexNormals();
	const material1 = new THREE.MeshBasicMaterial({ color: 0x808080, side: THREE.DoubleSide });
	const mesh = new THREE.Mesh( geometry1, material1 );
	//mesh.receiveShadow = true;
	//mesh.castShadow = true;
	return mesh;
}


function animate() {
	//ExplodeObjectFunction();
	requestAnimationFrame(animate);

	if (facingCamera != undefined){
		facingCamera.check(camera);
		dim0.update(camera);
		dim1.update(camera);

	}


	if (helper != undefined){
		helper.update();
	}
	// required if controls.enableDamping or controls.autoRotate are set to true
	if(controls){
	controls.update();
	}
	TWEEN.update();
	renderer.render(scene, camera);
}


function onWindowResize() {
	camera.aspect = window.innerWidth / window.innerHeight;
	camera.updateProjectionMatrix();
	renderer.setSize(window.innerWidth, window.innerHeight);

}


function exportDXF() {
	let d = new Drawing();
	d.addLineType('CONTINUOUS', '______', [])
	d.addLayer('l_blue', Drawing.ACI.BLUE, 'CONTINUOUS');
	d.setActiveLayer('l_blue');
	scene.traverse(function (child) {
		//console.log(child.name);
		if (child.name == "LeftMesh") {
			let array = [];
			for (let i = 0; i < child.geometry.vertices.length; i++) {
				let v1 = child.geometry.vertices[i];
				v1.applyMatrix4(child.matrix)
				let v1_array = [v1.x, v1.y, v1.z];
				array.push(v1_array);
			}
			array.push(array[0]);
			d.drawPolyline3d(array);
		}

		if (child.name == "wall") {
			let v1 = child.geometry.vertices[0];
			let v2 = child.geometry.vertices[1];
			let v3 = child.geometry.vertices[2];
			let v4 = child.geometry.vertices[3];


			v1.applyMatrix4(child.matrixWorld);
			v2.applyMatrix4(child.matrixWorld);
			v3.applyMatrix4(child.matrixWorld);
			v4.applyMatrix4(child.matrixWorld);
			//let point = new THREE.Vector3();
			//v1.localToWorld( point );

			let v1_array = [v1.x, v1.y, v1.z];
			let v2_array = [v2.x, v2.y, v2.z];
			let v3_array = [v3.x, v3.y, v3.z];
			let v4_array = [v4.x, v4.y, v4.z];

			let finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array]
			//d.addLayer('l_blue', Drawing.ACI.BLUE, 'CONTINUOUS')
			//.setActiveLayer('l_blue')
			//.drawPolyline3d(finalArray);
			d.drawPolyline3d(finalArray);

		}

		if (child.name == "Solar") {
			let v1 =  child.parent.children[0].geometry.vertices[0];  //child.geometry.vertices[0];
			let v2 = child.parent.children[0].geometry.vertices[1]; //child.geometry.vertices[1];
			let v3 = child.parent.children[0].geometry.vertices[2]; //child.geometry.vertices[2];
			let v4 = child.parent.children[0].geometry.vertices[3]; //child.geometry.vertices[3];

			v1.applyMatrix4(child.matrixWorld);
			v2.applyMatrix4(child.matrixWorld);
			v3.applyMatrix4(child.matrixWorld);
			v4.applyMatrix4(child.matrixWorld);

			let v1_array = [v1.x, v1.y, v1.z];
			let v2_array = [v2.x, v2.y, v2.z];
			let v3_array = [v3.x, v3.y, v3.z];
			let v4_array = [v4.x, v4.y, v4.z];

			let finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array];
			//d.addLayer('l_green', Drawing.ACI.GREEN, 'CONTINUOUS')
			//.setActiveLayer('l_green')
			//.drawPolyline3d(finalArray);
			d.drawPolyline3d(finalArray);


			v1 = child.parent.children[1].geometry.vertices[0];  //child.geometry.vertices[0];
			v2 = child.parent.children[1].geometry.vertices[1]; //child.geometry.vertices[1];
			v3 = child.parent.children[1].geometry.vertices[2]; //child.geometry.vertices[2];
			v4 = child.parent.children[1].geometry.vertices[3]; //child.geometry.vertices[3];

			v1.applyMatrix4(child.parent.children[1].matrixWorld);
			v2.applyMatrix4(child.parent.children[1].matrixWorld);
			v3.applyMatrix4(child.parent.children[1].matrixWorld);
			v4.applyMatrix4(child.parent.children[1].matrixWorld);

			v1_array = [v1.x, v1.y, v1.z];
			v2_array = [v2.x, v2.y, v2.z];
			v3_array = [v3.x, v3.y, v3.z];
			v4_array = [v4.x, v4.y, v4.z];

			finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array];
			//d.drawPolyline3d(finalArray);

		}

		if (child.name == "bottomSolar") {
			let v1 = child.geometry.vertices[0];  //child.geometry.vertices[0];
			let v2 = child.geometry.vertices[1]; //child.geometry.vertices[1];
			let v3 = child.geometry.vertices[2]; //child.geometry.vertices[2];
			let v4 = child.geometry.vertices[3]; //child.geometry.vertices[3];

			v1.applyMatrix4(child.matrixWorld);
			v2.applyMatrix4(child.matrixWorld);
			v3.applyMatrix4(child.matrixWorld);
			v4.applyMatrix4(child.matrixWorld);

			let v1_array = [v1.x, v1.y, v1.z];
			let v2_array = [v2.x, v2.y, v2.z];
			let v3_array = [v3.x, v3.y, v3.z];
			let v4_array = [v4.x, v4.y, v4.z];

			let finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array];
			//d.addLayer('l_green', Drawing.ACI.GREEN, 'CONTINUOUS')
			//.setActiveLayer('l_green')
			//.drawPolyline3d(finalArray);
			//d.drawPolyline3d(finalArray);
		}


	});

	let b = d.toDxfString()
	let a = b.replaceAll(NaN, 0.0);
	var blob = new Blob([a], { type: 'text/plain' }); // Generate Blob from the string
	//saveAs( blob, 'file.stl' ); //Save the Blob to file.stl
	//Following code will help you to save the file without FileSaver.js
	var link = document.createElement('a');
	link.style.display = 'none';
	document.body.appendChild(link);
	link.href = URL.createObjectURL(blob);
	link.download = 'sample.dxf';
	link.click();
}

function renderDormerusingJSON(jObject){
	for (let i=0; i < jObject.dormers.length ; i++){
		let arr = [];
		arr.push(jObject.dormers[i].path[0].x);
		arr.push(jObject.dormers[i].path[0].y);

		arr.push(jObject.dormers[i].path[1].x);
		arr.push(jObject.dormers[i].path[1].y);

		arr.push(jObject.dormers[i].path[2].x);
		arr.push(jObject.dormers[i].path[2].y);

		arr.push(jObject.dormers[i].path[3].x);
		arr.push(jObject.dormers[i].path[3].y);

		arr.push(jObject.dormers[i].path[4].x);
		arr.push(jObject.dormers[i].path[4].y);
		let roofID = jObject.dormers[i].id;
		let cntrx = jObject.centerxy.x
		let cntry = jObject.centerxy.y
		createDormerPts(arr,cntrx,cntry,roofID)
}
}

function renderHouse (jObject) {
	if(jObject && jObject.roofOutline.length>0){
	jsonObject=jObject;
	//referncePoint.x = jObject.roofOutline[0].path[0].x;
	//referncePoint.y = jObject.roofOutline[0].path[0].y;
	referncePoint.x = jsonObject.centerxy.x
	referncePoint.y = jsonObject.centerxy.y
    constant.LAT = jsonObject.lat
	constant.LON = jsonObject.lng
	constant.UTCOFFSET = jsonObject.offset
	let pixelPerFeet = jObject.pixelPerFeet;
	const d = new Date(2021, 11, 21, 13,00,00);   //21 December 2021  2PM Time
	calculateSolarPosition(d); //
	scene.add(directional_light);
	//sceneObject.position.x =  jObject.width/2;
	//sceneObject.position.y = -jObject.height/2;
	plane.geometry = new THREE.PlaneGeometry( jObject.width, jObject.height,16,16);
	compassplane.geometry = new THREE.PlaneGeometry( jObject.width/10, jObject.width/10);
  compassplane.position.x = -jObject.width/2.0 + ((jObject.width/10)/2.0)
	compassplane.position.y = -jObject.height/2.0 + ((jObject.width/10)/2.0)

	//Perceptive Projection
	camera.aspect = jObject.width/ jObject.height;
	camera.updateProjectionMatrix();
	camera.position.set(0,640/2,0)
	cameraHeight = 640/2;

	renderer.setSize(jObject.width, jObject.height);

	//Orthographic
	//let w = jObject.width;
	//let h = jObject.height;
	//let viewSize = h;
	//let aspectRatio = w / h;
	//camera.left = (-aspectRatio * viewSize) / 2
	//camera.right = (aspectRatio * viewSize) / 2
	//camera.top = viewSize / 2
	//camera.bottom = -viewSize / 2
	//camera.updateProjectionMatrix();


	//camera position Temp code
	var targetPosition = new THREE.Vector3( 00, 640/2,640/2);
	var duration = 3000;
	tweenCamera( targetPosition, duration );
	//camera.position.set(1500,1500,1500)
	camera.updateProjectionMatrix();
	//var img = new Image()
	//img.src = jObject.image
	//var texture = new THREE.Texture();
	//texture.needsUpdate = true;
	//texture.image = img;
	//plane.material.map = texture;
	sceneObject.add(planemap);
	var roofArrayObject = [];
	for (let i=0; i < jObject.roofOutline.length; i++){
		let roofHeight = jObject.roofOutline[i].roofHeight * pixelPerFeet;

		//console.log(roofHeight)
		let tilt = jObject.roofOutline[i].tilt
		if (tilt == undefined) {
			tilt = 0;
		}
		let tiltInRadian = convertDegreeToRadian(tilt)
		//tiltInRadian = 0
		let parapetHeight = 0 ;
		if (jObject.roofOutline[i].parapetHeight != undefined ){
			parapetHeight = jObject.roofOutline[i].parapetHeight * pixelPerFeet
		}else{
			parapetHeight = 0
		}

		let mesh = createHouseFromArray(jObject.roofOutline[i].path,roofHeight,tiltInRadian,0,parapetHeight,jObject.roofOutline[i].id);
		roofArrayObject.push(mesh)
		roofArrayObject.push(jObject.roofOutline[i].id)
	}
	for (let i=0; i < jObject.panelArray.length ; i++){
		for (let j=0; j < roofArrayObject.length ; j++){
			if (j%2 == 1){
				if(jObject.panelArray[i].roofId == roofArrayObject[j]){
					let mesh = roofArrayObject[j-1]
					//console.log(mesh)
					let tilt1 = jObject.panelArray[i].tilt
					if (tilt1 == undefined) {
						tilt1 = 0;
					}
					let roofID = jObject.panelArray[i].roofId;
					let tiltInRadian1 = convertDegreeToRadian(tilt1)  //0.45
					createSolarFromArray(jObject.panelArray[i].panels, mesh.children[0] , tiltInRadian1, roofID);
				}
			}
		}
	}
	for (let i=0; i < jObject.keepouts.length ; i++){
		let keepoutHeight = jObject.keepouts[i].height * pixelPerFeet + jObject.keepouts[i].baseHeight * pixelPerFeet;
		let baseHeight = jObject.keepouts[i].height * pixelPerFeet
		if(jObject.keepouts[i].type==3){
			var center = jObject.keepouts[i].center;
			var radius = jObject.keepouts[i].radius *  pixelPerFeet;
			createTree(center.x, center.y ,radius, keepoutHeight)
		}else{
			createKeepOut(jObject.keepouts[i], sceneObject , keepoutHeight, baseHeight, jObject.keepouts[i].roofID);
		}
	}

	/*
	for (let i=0; i < jObject.dormers.length ; i++){
			let arr = [];
			arr.push(jObject.dormers[i].path[0].x);
			arr.push(jObject.dormers[i].path[0].y);

			arr.push(jObject.dormers[i].path[1].x);
			arr.push(jObject.dormers[i].path[1].y);

			arr.push(jObject.dormers[i].path[2].x);
			arr.push(jObject.dormers[i].path[2].y);

			arr.push(jObject.dormers[i].path[3].x);
			arr.push(jObject.dormers[i].path[3].y);

			arr.push(jObject.dormers[i].path[4].x);
			arr.push(jObject.dormers[i].path[4].y);
			let roofID = jObject.dormers[i].id;
			let cntrx = jObject.centerxy.x
			let cntry = jObject.centerxy.y
			createDormerPts(arr,cntrx,cntry,roofID)
	}*/

	setInterval(function(){
		ChangeCamera()}, 150)
	}
}

var globalTime = 6;
var tempVariable = 0;
function ChangeCamera(){

	if (animateLight){
		if (tempVariable == 0) {
			if (globalTime >= 18) {
				globalTime = 6;
			}
			globalTime = globalTime + 1;
			const d = new Date(2021, 00, 1, globalTime ,00,00);   //21 December 2021  2PM Time
			//console.log(d);
			calculateSolarPosition(d); //
			console.log(globalTime);
			tempVariable = tempVariable + 1;
		}else{
			if (tempVariable == 1) {
				//console.log("15 Min");
				const d = new Date(2021, 00, 1, globalTime ,15,00);   //21 December 2021  2PM Time
				calculateSolarPosition(d); //
				//console.log(d);
				tempVariable = tempVariable + 1;
			}else if (tempVariable == 2){
				//console.log("30 Min");
				const d = new Date(2021, 00, 1, globalTime ,30,00);   //21 December 2021  2PM Time
				calculateSolarPosition(d); //
				//console.log(d);
				tempVariable = tempVariable + 1;

			}else if (tempVariable == 3){
				//console.log("45 Min");
				const d = new Date(2021, 00, 1, globalTime ,45,00);   //21 December 2021  2PM Time
				calculateSolarPosition(d); //
				//console.log(d);
				tempVariable = 0

			}else{
				tempVariable = 0
			}

		}
	}
}
function loadMapImage(url){
 //loader.load(url);
 let geometrymap = new THREE.PlaneGeometry( 640, 640);
	 materialmap = new THREE.MeshPhysicalMaterial({ color: 0xFFFFFF, side: THREE.DoubleSide });
	materialmap.map = loader.load(url);
	planemap = new THREE.Mesh(geometrymap, materialmap);
	planemap.receiveShadow = true;
	planemap.name = "Planemap";
}

function rotateAroundWorldAxis ( object3D , point, axis, angle ) {
    // rotate object around axis in world space (the axis passes through point)
    // axis is assumed to be normalized
    // assumes object does not have a rotated parent
	axis.normalize();
    var q = new THREE.Quaternion();

        q.setFromAxisAngle( axis, angle );

        object3D.applyQuaternion( q );

        object3D.position.sub( point );
        object3D.position.applyQuaternion( q );
        object3D.position.add( point );

}
function disposeScene(scene){
  modeltypeset=new Set();
  const sceneChildren = scene.children;
  sceneChildren.forEach((child) =>{
    const type = child.type;
    modeltypeset.add(type);
    if(child.type == "Object3D"){
      scene.remove(child);
      dispose3dObject(child,scene);
    }else if(child.type == 'AmbientLight' || type == 'DirectionalLight'){
      disposeLight(child);
    }else if(child.type == 'GridHelper'){
      //child.dispose();
    }else if(child.type == 'Mesh' ){
      disposeMesh(child);
    }
  })
}
function disposeLight(light,scene){
  light.dispose();
}
function disposegridHelper(helper){
  helper.dispose();
}
function dispose3dObject(object,scene){
  const children = object.children;
  children.forEach((child) =>{
    const type = child.type ;
    modeltypeset.add(type);
    if(child.type == "Object3D"){
      dispose3dObject(child,scene);
	  scene.remove(child);
    }else if(child.type == 'AmbientLight' || type == 'DirectionalLight'){
      disposeLight(child);
    }else if(child.type == 'GridHelper'){
      child.dispose();
    }else if(child.type == 'Mesh' ){
      disposeMesh(child);
    }
  });
}

function disposeMesh(mesh){
	const children = mesh.children;
	children.forEach((child) => {
		const type = child.type ;
    modeltypeset.add(type);
		if(child.type == "Object3D"){
		dispose3dObject(child);
		}else if(child.type == 'AmbientLight' || type == 'DirectionalLight'){
		disposeLight(child);
		}else if(child.type == 'GridHelper'){
		child.dispose();
		}else if(child.type == 'Mesh' ){
		disposeMesh(child);
		}
	})
  if(mesh.geometry){

    mesh.geometry.dispose();
 }
  if(mesh.material){
      var texture = mesh.material.map;

      if (texture) {
          texture.dispose();
      }
    mesh.material.dispose();
  }
//mesh.remove();
}
 function dispose3dObjects(){
	materialmap && materialmap.dispose && materialmap.dispose();
	compassPlanematerial && compassPlanematerial.dispose && compassPlanematerial.dispose();
	directional_light && directional_light.dispose && directional_light.dispose();
	plane && plane.material && plane.material.dispose()
	plane && plane.geometry &&plane.geometry.dispose();;
	compassplane && compassplane.geometry && compassplane.geometry.dispose();
	planemap && planemap.geometry && planemap.geometry.dispose()
	disposeScene(sceneObject);
	disposeScene(scene);
	while(scene.children.length > 0){
		scene.remove(scene.children[0]);
	}
	while(sceneObject.children.length > 0){
		sceneObject.remove(sceneObject.children[0]);
	}
	renderer.dispose();
}

function clearScene() {
  console.log("clearing schene");
	if (scene != undefined){
		//if (scene.children[0] != undefined){
		//	scene.children[0].parent.remove(scene.children[0]);
			while(scene.children.length > 0){
			scene.remove(scene.children[0]);
			}
				init();
				animate();
		}
}
function clearData(){
	//cancelAnimationFrame(frameAnimation);
  console.log("clearing schene data");
		jsonObject='';
		captureViewComplete=false;
    viewimageData={};
    vectorArray = [];
    solarArray = [];
    roofRotationAxisVector = [];
    roofPoint = [];
	}





function tweenCamera( targetPosition, duration ) {

    controls.enabled = false;

    var position = new THREE.Vector3().copy( camera.position );

    var tween = new TWEEN.Tween( position )
        .to( targetPosition, duration )
        //.easing( TWEEN.Easing.Back.InOut )
		//.easing( TWEEN.Easing.Linear.None )
        .onUpdate( function () {
            camera.position.copy( position );
            camera.lookAt( controls.target );
        } )
        .onComplete( function () {
            camera.position.copy( targetPosition );
            camera.lookAt( controls.target );
            controls.enabled = true;
			camera.updateProjectionMatrix();
        } )
        .start();

}


function showTopView(delay=1000){
	controls.autoRotate = false;
	controls.target.set(0, 0, 0);
	camera.updateProjectionMatrix();
	var targetPosition = new THREE.Vector3( 0, cameraHeight,0);
	var duration = delay;
	tweenCamera( targetPosition, duration );
}

function showIsometricView(delay=1000){
	controls.autoRotate = false;
	controls.target.set(0, 0, 0);
	camera.updateProjectionMatrix();
	var targetPosition = new THREE.Vector3( 0, cameraHeight/2.0,cameraHeight/2.0);
	var duration = delay;
	tweenCamera( targetPosition, duration );
}
function showFrontView(delay=1000){
	controls.autoRotate = false;
	controls.target.set(0, 0, 0);
	camera.updateProjectionMatrix();
	var targetPosition = new THREE.Vector3( 0, 0,cameraHeight);
	var duration = delay;
	tweenCamera( targetPosition, duration );
}

function showBackView(delay=1000){
	controls.autoRotate = false;
	controls.target.set(0, 0, 0);
	camera.updateProjectionMatrix();
	var targetPosition = new THREE.Vector3( 0, 0,-cameraHeight);
	var duration = delay;
	tweenCamera( targetPosition, duration );
}

function showLeftView(delay=1000){
	controls.autoRotate = false;
	controls.target.set(0, 0, 0);
	camera.updateProjectionMatrix();
	var targetPosition = new THREE.Vector3( -cameraHeight, 0,0);
	var duration = delay;
	tweenCamera( targetPosition, duration );

}

function showRightView(delay=1000){
	controls.autoRotate = false;
	controls.target.set(0, 0, 0);
	camera.updateProjectionMatrix();
	var targetPosition = new THREE.Vector3( cameraHeight, 0,0);
	var duration = delay;
	tweenCamera( targetPosition, duration );

}

var rotate = false;
var animateLight = false
function rotateAndAnimateLight(){
	controls.autoRotate = true
	animateLight = true
}

function animateLightFeature(){
	animateLight = true
}

function stopAnimation(){
	rotate = false;
	animateLight = false
	controls.autoRotate = false
}

function createNewHouse(){
	renderHouse(jsonObject3)
	let houseObject3D = new THREE.Object3D(); // houseObject3D = new THREE.Object3D();
	//let a = scene.children[0].children[4].clone()
//	let b = scene.children[0].children[3].clone()
	let a = scene.children[0].children[5]
	let b = scene.children[0].children[6]
	houseObject3D.add(a);
	houseObject3D.add(b);
	sceneObject.add(houseObject3D)
	houseObject3D.position.x = 1000;
	houseObject3D.position.y = 200;
	houseObject3D.rotation.z = 1.57
	var targetPosition = new THREE.Vector3( 50,200,0);
	var tween = new TWEEN.Tween( houseObject3D.position )
        .to( targetPosition, 4000 )
        //.easing( TWEEN.Easing.Back.InOut )
		//.easing( TWEEN.Easing.Linear.None )
        .onUpdate( function () {
        } )
        .onComplete( function () {
        } )
        .start();

	//houseObject3D.position.y = 200

	//houseObject3D.position.x = 50

	//sceneObject.children[5].rotation.z = 1.57
	//sceneObject.children[5].position.y = 200

}

function renderDomer(){
	vectorArray = [109.5,83.5,0, -89.5,76.5,0, -83.5,-56,0, 109,-56,0 ,   109,-56,0 ,  109.5,83.5,0];
	vectorArray2 = [109.5,83.5,0, -89.5,76.5,0, -83.5,209,0, 109,209,0,   109,209,0,   109.5,83.5,0];
	x1 =  156  //roofouttline [3]   //4 th element
	y1 =  258						//5
	x2 =  91						//7
	y2 =  216						//8
	var m = (y2 - y1) / (x2 - x1); // slope
	var c = (x2 * y1  -  x1 * y2) / (x2 - x1); // y-intercept
	console.log(m)
	console.log(c)
	var x = vectorArray[0]
	var height = ( m * x ) + c
	console.log(height)
	//var tiltAngle = 0.261799   //15  degree
	var tiltAngle =       0.17 // 10 degree //  0.087   // 7 degree
	renderHouseWithWalls(vectorArray, 244.1176136667046, tiltAngle)
	renderHouseWithWalls(vectorArray2, 244.1176136667046, -tiltAngle)
}




function renderHouseWithWalls (vectorArray , height, roofTilt){
	const polygon = new THREE.Shape();
	let houseObject3D = new THREE.Object3D();
	//if (vectorArray[0] == vectorArray[vectorArray.length - 3]) {
		//if (vectorArray[1] == vectorArray[vectorArray.length - 2]) {
			let i;
			for (i = 0; i < ((vectorArray.length / 3)-1); i++) {
				if (i == 0) {
					polygon.moveTo( vectorArray[i*3 + 0], -vectorArray[i*3 + 1] );
				} else {
					polygon.lineTo(vectorArray[i*3 + 0], -vectorArray[i * 3 + 1]);
				}
			}

			// Calculating roof rotation axis vector
	let roofRotationAxisVector = new THREE.Vector3( vectorArray[3] - vectorArray[0], -(vectorArray[4] - vectorArray[1]), vectorArray[5] - vectorArray[2] );
	roofRotationAxisVector.normalize ()
	// roofRotationAxisVector.y = -roofRotationAxisVector.y
    //console.log("roof rotation axis"- roofRotationAxisVector)

	// Calculating roof Rotation Point through which rotation axis passes
	let roofPoint = new THREE.Vector3( vectorArray[0], -vectorArray[1],  height ); //  vectorArray[2] );
	//console.log("roof rotation point" - roofPoint)
	//const point2 = new THREE.Vector3( vectorArray[0], -vectorArray[1],  roofHeight ); //  vectorArray[2] );


			const geometry = new THREE.ShapeGeometry(polygon);  //0x808080
			const material_Lroof = new THREE.MeshStandardMaterial({ color: 0xFF0000, side: THREE.DoubleSide });
			const mesh = new THREE.Mesh(geometry, material_Lroof);
			mesh.name = "DormerMesh";
			material_Lroof.transparent = true;
			material_Lroof.opacity = 1.0 //0.1;
			//material_Lroof.map = loader.load('image/roof.JPG');
			mesh.receiveShadow = true;
			mesh.castShadow = true;    //Material
			mesh.position.z =  height;
			houseObject3D.add(mesh);
			//scene.add(houseObject3D)
			sceneObject.add(houseObject3D)

			var roofTiltAngle = roofTilt
			rotateAroundWorldAxis(mesh,roofPoint,roofRotationAxisVector, roofTiltAngle)

			let object = mesh;
			object.updateMatrixWorld();
			let updatedArray = [];

			//r128
			for (let j = 0; j < object.geometry.parameters.shapes.curves.length; j++) {
				let vector =  new THREE.Vector3(object.geometry.parameters.shapes.curves[j].v1.x, object.geometry.parameters.shapes.curves[j].v1.y , 0.0 )//object.geometry.vertices[j].clone();
				vector.applyMatrix4(object.matrixWorld);
				updatedArray.push(vector)
			}


			//updatedArray.reverse();
			updatedArray.push(updatedArray[0].clone());
			for (let i = 0; i < updatedArray.length; i++) {
				vectorArray[i * 3 + 2] = updatedArray[i].y;
				//vectorArray[i * 3 + 1] = -updatedArray[i].z;   //Prashant Comment
				//vectorArray[i * 3 + 0] = updatedArray[i].x;   //Prashant Comment
			}

			for (i = 0; i <  updatedArray.length - 1 ; i++) {
				let v1 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y, updatedArray[i].z);
				let v2 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y , 0);
				let v3 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, 0);
				let v4 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, updatedArray[i+1].z);
				let v1_array = [v1.x, v1.y, v1.z];
				let v2_array = [v2.x, v2.y, v2.z];
				let v3_array = [v3.x, v3.y, v3.z];
				let v4_array = [v4.x, v4.y, v4.z];

				let finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array]
				let mesh1 = drawPolygon(v1, v2, v3, v4)
				mesh1.name = "wall";
				mesh1.castShadow = true; //
				mesh1.receiveShadow = true;
				//console.log("wall");
				//mesh1.rotation.x = 1.57 // (1.57 + roofTiltAngle); //-1.34//-Math.PI / 2;

				//mesh.add(mesh1);
				houseObject3D.add(mesh1);
				let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
				edges.material.linewidth = 2;
				mesh1.add(edges);
			}
}


function captureSixImages(){
	showTopView()
	setTimeout(function(){
		let canvas = document.getElementById("artifactCanvas");
		let dataURL = canvas.toDataURL();
		const Ink = document.createElement('a'); Ink.href = dataURL; Ink.download= "TopView"; Ink.click();

		showBackView()
		setTimeout(function(){
			let canvas = document.getElementById("artifactCanvas");
			let dataURL = canvas.toDataURL();
			const Ink = document.createElement('a'); Ink.href = dataURL; Ink.download= "BackView"; Ink.click();

			showFrontView()
			setTimeout(function(){
			let canvas = document.getElementById("artifactCanvas");
			let dataURL = canvas.toDataURL();
			const Ink = document.createElement('a'); Ink.href = dataURL; Ink.download= "FrontView"; Ink.click();
			showLeftView()
			setTimeout(function(){
			let canvas = document.getElementById("artifactCanvas");
			let dataURL = canvas.toDataURL();
			const Ink = document.createElement('a'); Ink.href = dataURL; Ink.download= "LeftView"; Ink.click();

			showRightView()
			setTimeout(function(){
			let canvas = document.getElementById("artifactCanvas");
			let dataURL = canvas.toDataURL();
			const Ink = document.createElement('a'); Ink.href = dataURL; Ink.download= "RightView"; Ink.click();

			showIsometricView()
			setTimeout(function(){
			let canvas = document.getElementById("artifactCanvas");
			let dataURL = canvas.toDataURL();
			const Ink = document.createElement('a'); Ink.href = dataURL; Ink.download= "IsometricView"; Ink.click();


	 		}, 3000);
	 		}, 3000);
	 		}, 3000);
		}, 3000);
	 	}, 3000);
	 }, 3000);
}
function renderDormerUsingPt(arrayOfPoints, roofID) {  // 6 Points
	let obj = sceneObject.getChildByName(roofID);
	if (obj) {

		let minHeight = obj.userData.minHeight
		let V1 = obj.userData.v1
		let V2 = obj.userData.v2
		let V3 = obj.userData.v3

		console.log("Height is ")
		console.log(minHeight)
		//console.log(updatedArray)
		let firstPointOfDormer = new THREE.Vector3( arrayOfPoints[0], 0, arrayOfPoints[1]);
		//let firstPointOfDormer = new THREE.Vector3( 109.5, 0, 83.5 );
		// Create plane on the tilted roof
    	let target = new THREE.Vector3(1,1,1);
		var plane = new THREE.Plane();
		plane.setFromCoplanarPoints ( V1, V2,V3);


			// Using Ray to calculate intersection point with plane
			let yAxisVector = new THREE.Vector3(0,1,0);
			var yAxisRay = new THREE.Ray(firstPointOfDormer, yAxisVector);
			var planeIntersectionPoint = yAxisRay.intersectPlane(plane, target);
			var dis = yAxisRay.distanceToPlane(plane);
			//console.log(target);
			//console.log(dis);


			// Didplay Plane for debugging
			const helper = new THREE.PlaneHelper( plane, 500, 0xffff00 );
			//scene.add( helper );

			// Display arrow for debugging
			//var arrowLength = 1000;
			//var arrowColor = 0x00ff00;
			//let arrowHelper = new THREE.ArrowHelper( new THREE.Vector3(0,1,0), firstPointOfDormer, arrowLength, arrowColor );
			//scene.add(arrowHelper)


			//109.5,83.5,0, -89.5,76.5,0, -83.5,-56,0, 109,-56,0 ,
			//vectorArray2 = [109.5,83.5,0, -89.5,76.5,0, -83.5,209,0, 109,209,0,   109,209,0,   109.5,83.5,0];
			let houseObject3D = new THREE.Object3D();
			houseObject3D.name = "Dormer";
			//Note Y should be minus Y
				let v1 = new THREE.Vector3(arrayOfPoints[0],-arrayOfPoints[1],dis);
				let v2 = new THREE.Vector3(arrayOfPoints[2],-arrayOfPoints[3],dis);
				let v3 = new THREE.Vector3(arrayOfPoints[4],-arrayOfPoints[5],minHeight);
				let v4 = new THREE.Vector3(arrayOfPoints[6],-arrayOfPoints[7],minHeight);
				let v5 = new THREE.Vector3(arrayOfPoints[8],-arrayOfPoints[9],minHeight);
				let v6 = new THREE.Vector3(arrayOfPoints[10],-arrayOfPoints[11],minHeight);

				let v7 = new THREE.Vector3(v3.x,v3.y,0);
				let v8 = new THREE.Vector3(v5.x,v5.y,0);


				let mesh1 = drawPolygon(v1, v2, v3, v4)
				mesh1.name = "DormerRoof";
				mesh1.castShadow = true; //
				mesh1.receiveShadow = true;
				houseObject3D.add(mesh1);

				let mesh2 = drawPolygon(v1, v2, v5, v6)
				mesh2.name = "DormerRoof";
				mesh2.castShadow = true; //
				mesh2.receiveShadow = true;
				houseObject3D.add(mesh2);

				let mesh3 = drawPolygon(v2, v3, v5, v2)
				mesh3.name = "DormerRoof";
				mesh3.castShadow = true; //
				mesh3.receiveShadow = true;
				//mesh3.material.color.r = 1.0
				//mesh3.material.color.g = 0.0
				houseObject3D.add(mesh3);

				let mesh4 = drawPolygon(v3, v7, v8, v5)
				mesh4.name = "DormerRoof";
				mesh4.castShadow = true; //
				mesh4.receiveShadow = true;
				houseObject3D.add(mesh4);

				sceneObject.add(houseObject3D);
				let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
				edges.material.linewidth = 2;
				//mesh1.add(edges);

				let edges2 = new THREE.EdgesHelper(mesh2, 0xffffff);
				edges2.material.linewidth = 2;
				//mesh2.add(edges2);

				let edges3 = new THREE.EdgesHelper(mesh3, 0xffffff);
				edges3.material.linewidth = 2;
				mesh3.add(edges3);

				let edges4 = new THREE.EdgesHelper(mesh4, 0xffffff);
				edges4.material.linewidth = 2;
				mesh4.add(edges4);

				updatedArray = [];
				updatedArray.push(v1);
				updatedArray.push(v2);
				updatedArray.push(v3);
				updatedArray.push(v4);
				updatedArray.push(v1);
				for (i = 0; i <  updatedArray.length - 1 ; i++) {
					let v1 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y, updatedArray[i].z);
					let v2 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y , 0);
					let v3 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, 0);
					let v4 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, updatedArray[i+1].z);
					let v1_array = [v1.x, v1.y, v1.z];
					let v2_array = [v2.x, v2.y, v2.z];
					let v3_array = [v3.x, v3.y, v3.z];
					let v4_array = [v4.x, v4.y, v4.z];

					let finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array]
					let mesh1 = drawPolygon(v1, v2, v3, v4)
					mesh1.name = "wall";
					mesh1.castShadow = true; //
					mesh1.receiveShadow = true;
					houseObject3D.add(mesh1);
					let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
					edges.material.linewidth = 2;
					mesh1.add(edges);
				}
				updatedArray = [];
				updatedArray.push(v1);
				updatedArray.push(v2);
				updatedArray.push(v5);
				updatedArray.push(v6);
				updatedArray.push(v1);
				for (i = 0; i <  updatedArray.length - 1 ; i++) {
					let v1 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y, updatedArray[i].z);
					let v2 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y , 0);
					let v3 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, 0);
					let v4 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, updatedArray[i+1].z);
					let v1_array = [v1.x, v1.y, v1.z];
					let v2_array = [v2.x, v2.y, v2.z];
					let v3_array = [v3.x, v3.y, v3.z];
					let v4_array = [v4.x, v4.y, v4.z];

					let finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array]
					let mesh1 = drawPolygon(v1, v2, v3, v4)
					mesh1.name = "wall";
					mesh1.castShadow = true; //
					mesh1.receiveShadow = true;
					houseObject3D.add(mesh1);
					let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
					edges.material.linewidth = 2;
					mesh1.add(edges);
				}
	}




}


function renderDormerTest(){


	//Use Z value from firstPointOfDormerWithZValue as height for the dormer

	let object = sceneObject.children[4].children[0];
	object.updateMatrixWorld();
	let updatedArray = [];

			//r128
			for (let j = 0; j < object.geometry.parameters.shapes.curves.length; j++) {
				let vector =  new THREE.Vector3(object.geometry.parameters.shapes.curves[j].v1.x, object.geometry.parameters.shapes.curves[j].v1.y , 0.0 )//object.geometry.vertices[j].clone();
				vector.applyMatrix4(object.matrixWorld);
				updatedArray.push(vector)
			}
			console.log(updatedArray)
			let firstPointOfDormer = new THREE.Vector3( 109.5, 0, 83.5 );


			// Create plane on the tilted roof
    		let target = new THREE.Vector3(1,1,1);
			var plane = new THREE.Plane();
			plane.setFromCoplanarPoints ( updatedArray[0],updatedArray[1],updatedArray[2]);



			// Using Ray to calculate intersection point with plane
			let yAxisVector = new THREE.Vector3(0,1,0);
			var yAxisRay = new THREE.Ray(firstPointOfDormer, yAxisVector);
			var planeIntersectionPoint = yAxisRay.intersectPlane(plane, target);
			var dis = yAxisRay.distanceToPlane(plane);
			//console.log(target);
			//console.log(dis);


			// Didplay Plane for debugging
			//const helper = new THREE.PlaneHelper( plane, 500, 0xffff00 );
			//scene.add( helper );

			// Display arrow for debugging
			//var arrowLength = 1000;
			//var arrowColor = 0x00ff00;
			//let arrowHelper = new THREE.ArrowHelper( new THREE.Vector3(0,1,0), firstPointOfDormer, arrowLength, arrowColor );
			//scene.add(arrowHelper)


			//109.5,83.5,0, -89.5,76.5,0, -83.5,-56,0, 109,-56,0 ,
			//vectorArray2 = [109.5,83.5,0, -89.5,76.5,0, -83.5,209,0, 109,209,0,   109,209,0,   109.5,83.5,0];
			let houseObject3D = new THREE.Object3D();
			houseObject3D.name = "Dormer";
			//Note Y should be minus Y
				let v1 = new THREE.Vector3(109.5, -83.5,244);
				let v2 = new THREE.Vector3(-89.5,-76.5,244);
				const geometry = new THREE.SphereGeometry( 15, 32, 16 );
				const material = new THREE.MeshBasicMaterial( { color: 0xffff00 } );
				const sphere = new THREE.Mesh( geometry, material );
				sphere.position.x = v2.x;
				sphere.position.y = v2.y;
				sphere.position.z = v2.z;
				houseObject3D.add( sphere );
				dragObject.push(sphere);


				dragControls = new THREE.DragControls(dragObject,camera,renderer.domElement);
				dragControls.addEventListener('dragstart', function (event) {
					controls.enabled = false;
				});
				dragControls.addEventListener('dragend', function (event) {
					controls.enabled = true;
					let v22 = new THREE.Vector3(dragObject[0].position.x,dragObject[0].position.y,dragObject[0].position.z);
					renderDormerTestNew(v22)
				})
			    //this.dragControls.deactivate();
				let v3 = new THREE.Vector3(-89.5,56,221);
				let v4 = new THREE.Vector3(109,56,221);

				let v11 = new THREE.Vector3(109.5, -83.5,244);
				let v22 = new THREE.Vector3(-89.5,-76.5,244);
				let v33 = new THREE.Vector3(-89.5,-209,221);
				let v44 = new THREE.Vector3(109,-209,221);


				let mesh1 = drawPolygon(v1, v2, v3, v4)
				mesh1.name = "wall";
				mesh1.castShadow = true; //
				mesh1.receiveShadow = true;
				houseObject3D.add(mesh1);

				let mesh2 = drawPolygon(v11, v22, v33, v44)
				mesh2.name = "wall";
				mesh2.castShadow = true; //
				mesh2.receiveShadow = true;
				houseObject3D.add(mesh2);

				sceneObject.add(houseObject3D);
				let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
				edges.material.linewidth = 2;
				mesh1.add(edges);

				let edges2 = new THREE.EdgesHelper(mesh2, 0xffffff);
				edges2.material.linewidth = 2;
				mesh2.add(edges2);

				updatedArray = [];
				updatedArray.push(v1);
				updatedArray.push(v2);
				updatedArray.push(v3);
				updatedArray.push(v4);
				updatedArray.push(v1);
				for (i = 0; i <  updatedArray.length - 1 ; i++) {
					let v1 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y, updatedArray[i].z);
					let v2 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y , 0);
					let v3 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, 0);
					let v4 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, updatedArray[i+1].z);
					let v1_array = [v1.x, v1.y, v1.z];
					let v2_array = [v2.x, v2.y, v2.z];
					let v3_array = [v3.x, v3.y, v3.z];
					let v4_array = [v4.x, v4.y, v4.z];

					let finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array]
					let mesh1 = drawPolygon(v1, v2, v3, v4)
					mesh1.name = "wall";
					mesh1.castShadow = true; //
					mesh1.receiveShadow = true;
					houseObject3D.add(mesh1);
					let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
					edges.material.linewidth = 2;
					mesh1.add(edges);
				}
				updatedArray = [];
				updatedArray.push(v11);
				updatedArray.push(v22);
				updatedArray.push(v33);
				updatedArray.push(v44);
				updatedArray.push(v11);
				for (i = 0; i <  updatedArray.length - 1 ; i++) {
					let v1 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y, updatedArray[i].z);
					let v2 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y , 0);
					let v3 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, 0);
					let v4 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, updatedArray[i+1].z);
					let v1_array = [v1.x, v1.y, v1.z];
					let v2_array = [v2.x, v2.y, v2.z];
					let v3_array = [v3.x, v3.y, v3.z];
					let v4_array = [v4.x, v4.y, v4.z];

					let finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array]
					let mesh1 = drawPolygon(v1, v2, v3, v4)
					mesh1.name = "wall";
					mesh1.castShadow = true; //
					mesh1.receiveShadow = true;
					houseObject3D.add(mesh1);
					let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
					edges.material.linewidth = 2;
					mesh1.add(edges);
				}



}



function renderDormerTestNew(v222){


	//Use Z value from firstPointOfDormerWithZValue as height for the dormer

	let object = sceneObject.children[4].children[0];
	object.updateMatrixWorld();
	let updatedArray = [];

			//r128
			for (let j = 0; j < object.geometry.parameters.shapes.curves.length; j++) {
				let vector =  new THREE.Vector3(object.geometry.parameters.shapes.curves[j].v1.x, object.geometry.parameters.shapes.curves[j].v1.y , 0.0 )//object.geometry.vertices[j].clone();
				vector.applyMatrix4(object.matrixWorld);
				updatedArray.push(vector)
			}
			console.log(updatedArray)
			let firstPointOfDormer = new THREE.Vector3( 109.5, 0, 83.5 );


			// Create plane on the tilted roof
    		let target = new THREE.Vector3(1,1,1);
			var plane = new THREE.Plane();
			plane.setFromCoplanarPoints ( updatedArray[0],updatedArray[1],updatedArray[2]);



			// Using Ray to calculate intersection point with plane
			let yAxisVector = new THREE.Vector3(0,1,0);
			var yAxisRay = new THREE.Ray(firstPointOfDormer, yAxisVector);
			var planeIntersectionPoint = yAxisRay.intersectPlane(plane, target);
			var dis = yAxisRay.distanceToPlane(plane);
			//console.log(target);
			//console.log(dis);


			// Didplay Plane for debugging
			//const helper = new THREE.PlaneHelper( plane, 500, 0xffff00 );
			//scene.add( helper );

			// Display arrow for debugging
			//var arrowLength = 1000;
			//var arrowColor = 0x00ff00;
			//let arrowHelper = new THREE.ArrowHelper( new THREE.Vector3(0,1,0), firstPointOfDormer, arrowLength, arrowColor );
			//scene.add(arrowHelper)


			//109.5,83.5,0, -89.5,76.5,0, -83.5,-56,0, 109,-56,0 ,
			//vectorArray2 = [109.5,83.5,0, -89.5,76.5,0, -83.5,209,0, 109,209,0,   109,209,0,   109.5,83.5,0];
			let houseObject3D = new THREE.Object3D();
			houseObject3D.name = "Dormer";
			//Note Y should be minus Y
				let v1 = new THREE.Vector3(109.5, -83.5,244);
				v222.z = 244;
				let v2 = v222 //new THREE.Vector3(-89.5,-76.5,244);
				const geometry = new THREE.SphereGeometry( 15, 32, 16 );
				const material = new THREE.MeshBasicMaterial( { color: 0xffff00 } );
				const sphere = new THREE.Mesh( geometry, material );
				sphere.position.x = v2.x;
				sphere.position.y = v2.y;
				sphere.position.z = v2.z;
				houseObject3D.add( sphere );
				dragObject.push(sphere);
				dragControls = new THREE.DragControls(dragObject,camera,renderer.domElement);
				dragControls.addEventListener('dragstart', function (event) {
					controls.enabled = false;
				});
				dragControls.addEventListener('dragend', function (event) {
					controls.enabled = true;
				});
			    //this.dragControls.deactivate();
				let v3 = new THREE.Vector3(v222.x /*-89.5*/,56,221);
				let v4 = new THREE.Vector3(109,56,221);

				let v11 = new THREE.Vector3(109.5, -83.5,244);
				let v22 = v222 //new THREE.Vector3(-89.5,-76.5,244);
				let v33 = new THREE.Vector3(v222.x /*-89.5*/,-209,221);
				let v44 = new THREE.Vector3(109,-209,221);


				let mesh1 = drawPolygon(v1, v2, v3, v4)
				mesh1.name = "wall";
				mesh1.castShadow = true; //
				mesh1.receiveShadow = true;
				houseObject3D.add(mesh1);

				let mesh2 = drawPolygon(v11, v22, v33, v44)
				mesh2.name = "wall";
				mesh2.castShadow = true; //
				mesh2.receiveShadow = true;
				houseObject3D.add(mesh2);

				sceneObject.add(houseObject3D);
				let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
				edges.material.linewidth = 2;
				mesh1.add(edges);

				let edges2 = new THREE.EdgesHelper(mesh2, 0xffffff);
				edges2.material.linewidth = 2;
				mesh2.add(edges2);

				updatedArray = [];
				updatedArray.push(v1);
				updatedArray.push(v2);
				updatedArray.push(v3);
				updatedArray.push(v4);
				updatedArray.push(v1);
				for (i = 0; i <  updatedArray.length - 1 ; i++) {
					let v1 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y, updatedArray[i].z);
					let v2 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y , 0);
					let v3 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, 0);
					let v4 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, updatedArray[i+1].z);
					let v1_array = [v1.x, v1.y, v1.z];
					let v2_array = [v2.x, v2.y, v2.z];
					let v3_array = [v3.x, v3.y, v3.z];
					let v4_array = [v4.x, v4.y, v4.z];

					let finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array]
					let mesh1 = drawPolygon(v1, v2, v3, v4)
					mesh1.name = "wall";
					mesh1.castShadow = true; //
					mesh1.receiveShadow = true;
					houseObject3D.add(mesh1);
					let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
					edges.material.linewidth = 2;
					mesh1.add(edges);
				}
				updatedArray = [];
				updatedArray.push(v11);
				updatedArray.push(v22);
				updatedArray.push(v33);
				updatedArray.push(v44);
				updatedArray.push(v11);
				for (i = 0; i <  updatedArray.length - 1 ; i++) {
					let v1 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y, updatedArray[i].z);
					let v2 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y , 0);
					let v3 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, 0);
					let v4 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, updatedArray[i+1].z);
					let v1_array = [v1.x, v1.y, v1.z];
					let v2_array = [v2.x, v2.y, v2.z];
					let v3_array = [v3.x, v3.y, v3.z];
					let v4_array = [v4.x, v4.y, v4.z];

					let finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array]
					let mesh1 = drawPolygon(v1, v2, v3, v4)
					mesh1.name = "wall";
					mesh1.castShadow = true; //
					mesh1.receiveShadow = true;
					houseObject3D.add(mesh1);
					let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
					edges.material.linewidth = 2;
					mesh1.add(edges);
				}



}
function renderDormerTest2(){  //For jsonObject3

	//Use Z value from firstPointOfDormerWithZValue as height for the dormer

	let object = sceneObject.children[4].children[0];
	object.updateMatrixWorld();
	let updatedArray = [];

			//r128
			for (let j = 0; j < object.geometry.parameters.shapes.curves.length; j++) {
				let vector =  new THREE.Vector3(object.geometry.parameters.shapes.curves[j].v1.x, object.geometry.parameters.shapes.curves[j].v1.y , 0.0 )//object.geometry.vertices[j].clone();
				vector.applyMatrix4(object.matrixWorld);
				updatedArray.push(vector)
			}
			console.log(updatedArray)
			//805-675
			//377-312
			let firstPointOfDormer = new THREE.Vector3( 130, 0, 65 );


			// Create plane on the tilted roof
    		let target = new THREE.Vector3(1,1,1);
			var plane = new THREE.Plane();
			plane.setFromCoplanarPoints ( updatedArray[0],updatedArray[1],updatedArray[2]);



			// Using Ray to calculate intersection point with plane
			let yAxisVector = new THREE.Vector3(0,1,0);
			var yAxisRay = new THREE.Ray(firstPointOfDormer, yAxisVector);
			var planeIntersectionPoint = yAxisRay.intersectPlane(plane, target);
			var dis = yAxisRay.distanceToPlane(plane);
			console.log(target);
			console.log(dis);


			// Didplay Plane for debugging
			//const helper = new THREE.PlaneHelper( plane, 500, 0xffff00 );
			//scene.add( helper );

			// Display arrow for debugging
			//var arrowLength = 1000;
			//var arrowColor = 0x00ff00;
			//let arrowHelper = new THREE.ArrowHelper( new THREE.Vector3(0,1,0), firstPointOfDormer, arrowLength, arrowColor );
			//scene.add(arrowHelper)


			//109.5,83.5,0, -89.5,76.5,0, -83.5,-56,0, 109,-56,0 ,
			//vectorArray2 = [109.5,83.5,0, -89.5,76.5,0, -83.5,209,0, 109,209,0,   109,209,0,   109.5,83.5,0];
			let houseObject3D = new THREE.Object3D();
			houseObject3D.name = "Dormer";
			//Note Y should be minus Y
				let v1 = new THREE.Vector3(103, -65,dis);
				let v2 = new THREE.Vector3(230,-65,dis);
				let v3 = new THREE.Vector3(230,-136,74);   //Find Min Distance
				let v4 = new THREE.Vector3(103,-136,74);

				let v11 = new THREE.Vector3(103, -65,dis);
				let v22 = new THREE.Vector3(230,-65,dis);
				let v33 = new THREE.Vector3(230,6,74);
				let v44 = new THREE.Vector3(103,6,74);


				let mesh1 = drawPolygon(v1, v2, v3, v4)
				mesh1.name = "wall";
				mesh1.castShadow = true; //
				mesh1.receiveShadow = true;
				houseObject3D.add(mesh1);

				let mesh2 = drawPolygon(v11, v22, v33, v44)
				mesh2.name = "wall";
				mesh2.castShadow = true; //
				mesh2.receiveShadow = true;
				houseObject3D.add(mesh2);

				sceneObject.add(houseObject3D);
				let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
				edges.material.linewidth = 2;
				mesh1.add(edges);

				let edges2 = new THREE.EdgesHelper(mesh2, 0xffffff);
				edges2.material.linewidth = 2;
				mesh2.add(edges2);

				updatedArray = [];
				updatedArray.push(v1);
				updatedArray.push(v2);
				updatedArray.push(v3);
				updatedArray.push(v4);
				updatedArray.push(v1);
				for (i = 0; i <  updatedArray.length - 1 ; i++) {
					let v1 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y, updatedArray[i].z);
					let v2 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y , 0);
					let v3 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, 0);
					let v4 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, updatedArray[i+1].z);
					let v1_array = [v1.x, v1.y, v1.z];
					let v2_array = [v2.x, v2.y, v2.z];
					let v3_array = [v3.x, v3.y, v3.z];
					let v4_array = [v4.x, v4.y, v4.z];

					let finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array]
					let mesh1 = drawPolygon(v1, v2, v3, v4)
					mesh1.name = "wall";
					mesh1.castShadow = true; //
					mesh1.receiveShadow = true;
					houseObject3D.add(mesh1);
					let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
					edges.material.linewidth = 2;
					mesh1.add(edges);
				}
				updatedArray = [];
				updatedArray.push(v11);
				updatedArray.push(v22);
				updatedArray.push(v33);
				updatedArray.push(v44);
				updatedArray.push(v11);
				for (i = 0; i <  updatedArray.length - 1 ; i++) {
					let v1 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y, updatedArray[i].z);
					let v2 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y , 0);
					let v3 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, 0);
					let v4 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, updatedArray[i+1].z);
					let v1_array = [v1.x, v1.y, v1.z];
					let v2_array = [v2.x, v2.y, v2.z];
					let v3_array = [v3.x, v3.y, v3.z];
					let v4_array = [v4.x, v4.y, v4.z];

					let finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array]
					let mesh1 = drawPolygon(v1, v2, v3, v4)
					mesh1.name = "wall";
					mesh1.castShadow = true; //
					mesh1.receiveShadow = true;
					houseObject3D.add(mesh1);
					let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
					edges.material.linewidth = 2;
					mesh1.add(edges);
				}


}


function renderDormer (roofID, vectorArray) { //vector array is an array of 4 points

}

function makeTextSprite( message, parameters )
{
	if ( parameters === undefined ) parameters = {};

	var fontface = parameters.hasOwnProperty("fontface") ?
		parameters["fontface"] : "Arial";

	var fontsize = parameters.hasOwnProperty("fontsize") ?
		parameters["fontsize"] : 18;

	var borderThickness = parameters.hasOwnProperty("borderThickness") ?
		parameters["borderThickness"] : 4;

	var borderColor = parameters.hasOwnProperty("borderColor") ?
		parameters["borderColor"] : { r:0, g:0, b:0, a:1.0 };

	var backgroundColor = parameters.hasOwnProperty("backgroundColor") ?
		parameters["backgroundColor"] : { r:255, g:255, b:255, a:1.0 };

	//var spriteAlignment = new THREE.SpriteAlignment.topLeft;

	var canvas = document.createElement('canvas');
	var context = canvas.getContext('2d');
	context.font = "Bold " + fontsize + "px " + fontface;

	// get size data (height depends only on font size)
	var metrics = context.measureText( message );
	var textWidth = metrics.width;

	// background color
	context.fillStyle   = "rgba(" + backgroundColor.r + "," + backgroundColor.g + ","
								  + backgroundColor.b + "," + backgroundColor.a + ")";
	// border color
	context.strokeStyle = "rgba(" + borderColor.r + "," + borderColor.g + ","
								  + borderColor.b + "," + borderColor.a + ")";

	context.lineWidth = borderThickness;
	roundRect(context, borderThickness/2, borderThickness/2, textWidth + borderThickness, fontsize * 1.4 + borderThickness, 6);
	// 1.4 is extra height factor for text below baseline: g,j,p,q.

	// text color
	context.fillStyle = "rgba(0, 0, 0, 1.0)";

	context.fillText( message, borderThickness, fontsize + borderThickness);

	// canvas contents will be used for a texture
	var texture = new THREE.Texture(canvas)
	texture.needsUpdate = true;

	var spriteMaterial = new THREE.SpriteMaterial(
		{ map: texture, useScreenCoordinates: false } );
	var sprite = new THREE.Sprite( spriteMaterial );
	sprite.scale.set(100,50,1.0);
	return sprite;
}

// function for drawing rounded rectangles
function roundRect(ctx, x, y, w, h, r)
{
    ctx.beginPath();
    ctx.moveTo(x+r, y);
    ctx.lineTo(x+w-r, y);
    ctx.quadraticCurveTo(x+w, y, x+w, y+r);
    ctx.lineTo(x+w, y+h-r);
    ctx.quadraticCurveTo(x+w, y+h, x+w-r, y+h);
    ctx.lineTo(x+r, y+h);
    ctx.quadraticCurveTo(x, y+h, x, y+h-r);
    ctx.lineTo(x, y+r);
    ctx.quadraticCurveTo(x, y, x+r, y);
    ctx.closePath();
    ctx.fill();
	ctx.stroke();
}

function createDormerPts(array,centerX,centerY,roofID){
	let finalArray = [];
	finalArray.push(array[0] - centerX)   //V1
	finalArray.push(array[1] - centerY)
	let secondPtX = (array[4] + array[6]) /2.0    //V2
	let secondPtY = (array[5] + array[7]) /2.0
	finalArray.push(secondPtX - centerX)
	finalArray.push(secondPtY - centerY)
	finalArray.push(array[4] - centerX)   //V3
	finalArray.push(array[5] - centerY)
	finalArray.push(array[2] - centerX)   //V4
	finalArray.push(array[3] - centerY)
	finalArray.push(array[6] - centerX)   //V5
	finalArray.push(array[7] - centerY)
	finalArray.push(array[8] - centerX)   //V6
	finalArray.push(array[9] - centerY)
	renderDormerUsingPt(finalArray,roofID);
}

function captureViews(){
	console.log('inside capture view');
	controls.autoRotate = false
    captureViewComplete=false;
    viewimageData={};
	showTopView(0);
	setTimeout(function(){
		let canvas = document.getElementById("artifactCanvas");
		let dataURL = canvas.toDataURL();
		//const Ink = document.createElement('a'); Ink.href = dataURL; Ink.download= 'topView.png'; Ink.click();
		viewimageData['topImage']=dataURL;
		showFrontView(0);
		setTimeout(function(){
			let canvas = document.getElementById("artifactCanvas");
			let dataURL = canvas.toDataURL();
			//const Ink = document.createElement('a'); Ink.href = dataURL; Ink.download= 'topView.png'; Ink.click();
			viewimageData['frontImage']=dataURL;
			showBackView(0);
			setTimeout(function(){
				let canvas = document.getElementById("artifactCanvas");
				let dataURL = canvas.toDataURL();
			//	const Ink = document.createElement('a'); Ink.href = dataURL; Ink.download= 'topView.png'; Ink.click();
				viewimageData['backImage']=dataURL;
				showLeftView(0)
				setTimeout(function(){
					let canvas = document.getElementById("artifactCanvas");
					let dataURL = canvas.toDataURL();
				//	const Ink = document.createElement('a'); Ink.href = dataURL; Ink.download= 'topView.png'; Ink.click();
					viewimageData['leftImage']=dataURL;
					showRightView(0)
					setTimeout(function(){
						let canvas = document.getElementById("artifactCanvas");
						let dataURL = canvas.toDataURL();
				//		const Ink = document.createElement('a'); Ink.href = dataURL; Ink.download= 'topView.png'; Ink.click();
						viewimageData['rightImage']=dataURL;
						captureViewComplete=true;
					},10)
				},10)
			},10)
		},10)
	 },10);
}
