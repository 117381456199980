var constant_s = {
    CANVAS_HEIGHT : 600,
    CANVAS_WIDTH : 600,
    CAMERA_NEAR : 0.1,
    CAMERA_FAR : 10000,
    CAMERA_FOV : 75,
    GROUND_PLANE_HEIGHT : 1000,
    GROUND_PLANE_WIDTH : 1000,
    GROUND_PLANE_Y :-0.115,
    CAMERA_X : 0,
    CAMERA_Y : 600,
    CAMERA_Z : 0,
    LAT : 12.7822,
	LON : 77.6585,
	UTCOFFSET : 5.5
}
var constant = {
    CANVAS_HEIGHT : 600,
    CANVAS_WIDTH : 600,
    CAMERA_NEAR : 0.1,
    CAMERA_FAR : 10000,
    CAMERA_FOV : 75,
    GROUND_PLANE_HEIGHT : 1000,
    GROUND_PLANE_WIDTH : 1000,
    GROUND_PLANE_Y :-0.115,
    CAMERA_X : 0,
    CAMERA_Y : 600,
    CAMERA_Z : 0,
    LAT : 12.7822,
	LON : 77.6585,
	UTCOFFSET : 5.5,
    COMPASSWIDTH : 50,
    COMPASSHEIGHT : 50
}