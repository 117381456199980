

var loader_s;
var scene_s;
var camera_s;
var referncePoint_s = new THREE.Vector2(0, 0);
var height_s = 50;
var leftAngle_s = 0;
var leftAngleInradian_s = convertDegreeToRadian(leftAngle_s);
var renderer_s;
var solarAngle_s = 0;
var directional_light_s = new THREE.DirectionalLight(0xffffff ,1.5);
var frameAnimation_s;
var plane_s = null
var angleTest_s = 0;
var shadowImages_s;
var sceneObject_s = new THREE.Object3D();
var shadeData_s=[];
var shadeDataObj_s={};
var vectorArray_s = [];
var solarArray_s = [];
var roofRotationAxisVector_s = [];
var roofPoint_s = [];
var currentHouseObject_s;
var captureComplete=false;
// var houseObject3D_s = new THREE.Object3D();
function timechange_s(e){
	var d = new Date(e.target.value);
	calculateSolarPosition_s(d)
  }


function calculateSolarPosition_s(userPickedDate){
	var lat = constant_s.LAT
	var lon = constant_s.LON
	var utcOffset = constant_s.UTCOFFSET
	var month = userPickedDate.getMonth() + 1;
	var year =  userPickedDate.getFullYear()
	var day = userPickedDate.getDate()
	var hr = userPickedDate.getHours()
	var mi =   userPickedDate.getMinutes()
	var sec = userPickedDate.getSeconds()
	var mins = hr*60 + mi  + sec /60.0
	var jday = getJD(year, month , day);
	var total = jday + mins/1440.0 - utcOffset/24.0
	var T = calcTimeJulianCent(total)
	var azel = calcAzEl(T, mins, lat, lon, utcOffset)
	var solnoon = calcSolNoon(jday, lon, utcOffset)
	var rise = calcSunriseSet(1, jday, lat, lon, utcOffset)
	var set  = calcSunriseSet(0, jday, lat,lon, utcOffset)

	var eqTime = calcEquationOfTime(T)
	var theta  = calcSunDeclination(T);
	setSolar_s(azel.azimuth, azel.elevation);
}

function init_s() {

	sceneObject_s = new THREE.Object3D();
	const canvas1 = document.getElementById("artifactCanvas_shade");
	//camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
	//camera = new THREE.PerspectiveCamera(constant.CAMERA_FOV, constant.CANVAS_WIDTH / constant.CANVAS_HEIGHT, constant.CAMERA_NEAR, constant.CAMERA_FAR);
	camera_s = new THREE.OrthographicCamera(-1000, 1000, 1000, -1000, 0.1, 10000)

	camera_s.position.z = constant_s.CAMERA_Z;
	camera_s.position.y = constant_s.CAMERA_Y;
	scene_s = new THREE.Scene();
	scene_s.add(sceneObject_s);
	loader_s = new THREE.TextureLoader();

	//renderer = new THREE.WebGLRenderer({ canvas: canvas1 });
	//renderer = new THREE.WebGLRenderer({ canvas: canvas1, antialias :true, logarithmicDepthBuffer : true  });
	renderer_s = new THREE.WebGLRenderer({ canvas: canvas1, antialias :true, logarithmicDepthBuffer : true, preserveDrawingBuffer : true  });
	//renderer.setSize(window.innerWidth - 270, window.innerHeight - 10);
	renderer_s.setSize(constant_s.CANVAS_WIDTH, constant_s.CANVAS_HEIGHT);
	renderer_s.setClearColor(0x787c82);
	renderer_s.shadowMap.enabled = true;
	//renderer.shadowMapSoft =true;

	renderer_s.shadowMap.type = THREE.PCFSoftShadowMap
	//renderer.shadowMap.type = THREE.BasicShadowMap
    //renderer.shadowMap.type = THREE.PCFShadowMap
    //renderer.shadowMap.type = THREE.VSMShadowMap

	//renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
	//document.body.appendChild(renderer.domElement);



	var ambient_light = new THREE.AmbientLight(0xffffff,0.8); // soft white light
	ambient_light.name = "light";
	ambient_light.position.y = 1500
	scene_s.add(ambient_light);

	                 //0xffffff, 1.0);
	directional_light_s.name = "Dir_light";
	directional_light_s.shadow.mapSize.width = 4096
	directional_light_s.shadow.mapSize.height = 4096
	//directional_light.shadow.camera.near = 10
	//directional_light.shadow.camera.far = 1000
	//directional_light.shadow.bias = 0.0000001

	directional_light_s.castShadow = true;   //Enable
	//const d = new Date(2021, 11, 21, 14);   //21 December 2021  2PM Time
	//calculateSolarPosition(d); //

	//directional_light.position.x = 300;
	//directional_light.position.y = 600;
	directional_light_s.shadow.camera.left = -1500;
	directional_light_s.shadow.camera.right = 1500;
	directional_light_s.shadow.camera.bottom = -1500;
	directional_light_s.shadow.camera.top = 1500;
	directional_light_s.shadow.camera.near = 10;
	directional_light_s.shadow.camera.far = 100000;

	directional_light_s.shadow.bias =  -0.000009;


	controls_s = new THREE.OrbitControls(camera_s, renderer_s.domElement); // TrackballControls
	//controls = new THREE.TrackballControls(camera, renderer.domElement)
	let geometry = new THREE.PlaneGeometry( constant_s.GROUND_PLANE_WIDTH, constant_s.GROUND_PLANE_HEIGHT);
	const material = new THREE.MeshPhysicalMaterial({ color: 0xE6F5CB, side: THREE.DoubleSide });
	material.map = loader_s.load('https://threejs.org/examples/textures/terrain/grasslight-big.jpg');
	//material.map = loader.load('image/grass.png');
	plane_s = new THREE.Mesh(geometry, material);
	plane_s.receiveShadow = true;
	plane_s.name = "Plane"

	//plane.castShadow = true;
	//plane.rotation.x = -Math.PI / 2;

	//plane.position.y = constant.GROUND_PLANE_Y;
	//plane.position.x = 500;
	//plane.position.y = -500;
	//scene.add(plane);
	sceneObject_s.add(plane_s);
	sceneObject_s.rotation.x = -Math.PI / 2;

	//sceneObject.add( axesHelper );

}

function setSolar_s (angle1 , angle2){ //Theta //Fi
	let radius = 500;
	angle1 = 90 - angle1;
	let angleInDegree1 = angle1 * (Math.PI / 180);
	let angleInDegree2 = angle2 * (Math.PI / 180);
	let x = (radius * (Math.cos(angleInDegree2))) * (Math.cos(angleInDegree1));
	let z = (radius * (Math.cos(angleInDegree2))) * (Math.sin(angleInDegree1));
	let y = radius * (Math.sin(angleInDegree2))
	directional_light_s.position.set(x,y,-z);

}

function createTree_s(position_x, position_y ,radius, treeHeight) {
	let treeobject = new THREE.Object3D()
	treeobject.name = "tree";
	const geometry = new THREE.CylinderGeometry(5, 5, treeHeight, radius);
	const material = new THREE.MeshPhysicalMaterial({ color: 0xF3760E, side: THREE.DoubleSide });
	const cylinder = new THREE.Mesh(geometry, material);
	cylinder.castShadow = true; //default is false
	//cylinder.receiveShadow = true; //default.
	treeobject.add(cylinder);

	const geometry1 = new THREE.SphereGeometry(radius,32,32);
	const material1 = new THREE.MeshPhysicalMaterial({ color: 0x20F30E, side: THREE.DoubleSide });
	const sphere = new THREE.Mesh(geometry1, material1);
	sphere.castShadow = true; //default is false
	//sphere.receiveShadow = true; //default.
	sphere.position.y = treeHeight / 2;

	treeobject.add(sphere);

	if (referncePoint_s.x == 0 & referncePoint_s.y == 0){
		treeobject.position.y = 0
		treeobject.position.x = 0
		//cylinder.position.x =  position_x - referncePoint.x
		//cylinder.position.y =  referncePoint.y - position_y
	}else{
		treeobject.position.x =  position_x - referncePoint_s.x
		treeobject.position.y =  referncePoint_s.y - position_y
	}

	treeobject.position.z = treeHeight/2
	treeobject.rotation.x = Math.PI / 2;
	//cylinder.rotation.x = Math.PI / 2;
	//sceneObject.add(cylinder);
	sceneObject_s.add(treeobject);

}
function refreshScenes_s() {

	//solarAngle = 0;
	let m = scene_s.getObjectByName("LeftMesh");
	if (m != undefined) {
		m.parent.remove(m);
	}

	let t = scene_s.getObjectByName("tree", true);
	if (t != undefined) {
		//t.parent.remove(t);
	}
}

function refreshAll_s() {
	solarAngle_s = 0;
	refreshScene_s();
	refreshScene_s();
	refreshScene_s();
	refreshScene_s();
}

function createLeftRoofFromArray_s(path, roofHeight , roofTiltAngle, solarPanelAngle,parapetHeight) {
	vectorArray_s = [];
	// Path from JSON object
	let jsonVecArray = path;
	let houseObject3D = new THREE.Object3D();

	// Adding Z value as Zero and putiing it in single array
	for (let i = 0; i < jsonVecArray.length; i++) {
		let data = jsonVecArray[i];
		vectorArray_s.push(data.x)
		vectorArray_s.push(data.y)
		vectorArray_s.push(0);
	}

	// Moving to local co-ordinate system where point 1 is considered as origin
	for (let i = 0; i < (vectorArray_s.length / 3); i++) {
		vectorArray_s[i * 3 + 0] = vectorArray_s[i * 3 + 0] - referncePoint_s.x;
		vectorArray_s[i * 3 + 1] = vectorArray_s[i * 3 + 1] - referncePoint_s.y;
	}

	// Calculating roof rotation axis vector
	let roofRotationAxisVector = new THREE.Vector3( vectorArray_s[3] - vectorArray_s[0], -(vectorArray_s[4] - vectorArray_s[1]), vectorArray_s[5] - vectorArray_s[2] );
	roofRotationAxisVector.normalize ()
	// roofRotationAxisVector.y = -roofRotationAxisVector.y
    console.log("roof rotation axis"- roofRotationAxisVector)

	// Calculating roof Rotation Point through which rotation axis passes
	let roofPoint = new THREE.Vector3( vectorArray_s[0], -vectorArray_s[1],  roofHeight ); //  vectorArray[2] );
	console.log("roof rotation point" - roofPoint)
	//const point2 = new THREE.Vector3( vectorArray[0], -vectorArray[1],  roofHeight ); //  vectorArray[2] );


	// var origin = new THREE.Vector3( 0, 0, roofHeight );
	// var axisvector = new THREE.Vector3( 0, 0, 1 );


	var arrowLength = 100;
	var arrowColor = 0x00ff00;
	let arrowHelper = new THREE.ArrowHelper( roofRotationAxisVector_s, roofPoint_s, arrowLength, arrowColor );
	sceneObject_s.add( arrowHelper );// var origin = new THREE.Vector3( 0, 0, roofHeight );
	var groundPoint = new THREE.Vector3( vectorArray_s[0], -vectorArray_s[1], 0 );
	var axisvector = new THREE.Vector3( 0, 0, 1 );
	var arrowLength = 10 * jsonObject_s.pixelPerFeet;
	var arrowColor = 0xff0000;
	let arrowHelper2 = new THREE.ArrowHelper( axisvector, groundPoint, arrowLength, arrowColor );
	sceneObject_s.add( arrowHelper2 );

	const polygon = new THREE.Shape();
	//if (vectorArray[0] == vectorArray[vectorArray.length - 3]) {
		//if (vectorArray[1] == vectorArray[vectorArray.length - 2]) {
			let i;
			for (i = 0; i < ((vectorArray_s.length / 3) -1); i++) {
				if (i == 0) {
					polygon.moveTo( vectorArray_s[i*3 + 0], -vectorArray_s[i*3 + 1] );
				} else {
					polygon.lineTo(vectorArray_s[i*3 + 0], -vectorArray_s[i * 3 + 1]);
				}
			}
			const geometry = new THREE.ShapeGeometry(polygon,100);  //0x808080
			const material_Lroof = new THREE.MeshStandardMaterial({ color: 0xffffff, side: THREE.DoubleSide });
			const mesh = new THREE.Mesh(geometry, material_Lroof);
			mesh.name = "LeftMesh";
			material_Lroof.transparent = true;
			material_Lroof.opacity = 1.0 //0.1;
			//material_Lroof.map = loader.load('image/roof.JPG');
			mesh.receiveShadow = true;
			mesh.castShadow = true;    //Material

			houseObject3D.add(mesh);
			//scene.add(houseObject3D)
			sceneObject_s.add(houseObject3D)

			// houseObject3D_s.add(mesh);
			// //scene.add(houseObject3D_s)
			// sceneObject_s.add(houseObject3D_s)
			//scene.add(mesh);
			mesh.position.z =  roofHeight //height;
			rotateAroundWorldAxis_s(mesh,roofPoint,roofRotationAxisVector, roofTiltAngle)
			let object = mesh;
			object.updateMatrixWorld();
			let updatedArray = [];

			//r128
			for (let j = 0; j < object.geometry.parameters.shapes.curves.length; j++) {
				let vector =  new THREE.Vector3(object.geometry.parameters.shapes.curves[j].v1.x, object.geometry.parameters.shapes.curves[j].v1.y , 0.0 )//object.geometry.vertices[j].clone();
				vector.applyMatrix4(object.matrixWorld);
				updatedArray.push(vector)
			}

			//r120
			/*
			for (let j = 0; j < object.geometry.vertices.length; j++) {
				let vector = object.geometry.vertices[j].clone();
				vector.applyMatrix4(object.matrixWorld);
				updatedArray.push(vector)
			}*/



			//updatedArray.reverse();
			updatedArray.push(updatedArray[0].clone());
			for (let i = 0; i < updatedArray.length; i++) {
				vectorArray_s[i * 3 + 2] = updatedArray[i].y;
				//vectorArray[i * 3 + 1] = -updatedArray[i].z;   //Prashant Comment
				//vectorArray[i * 3 + 0] = updatedArray[i].x;   //Prashant Comment
			}

			for (i = 0; i <  updatedArray.length - 1 ; i++) {
				let v1 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y, updatedArray[i].z);
				let v2 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y , 0);
				let v3 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, 0);
				let v4 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, updatedArray[i+1].z);
				let v1_array = [v1.x, v1.y, v1.z];
				let v2_array = [v2.x, v2.y, v2.z];
				let v3_array = [v3.x, v3.y, v3.z];
				let v4_array = [v4.x, v4.y, v4.z];

				let finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array]
				let mesh1 = drawPolygon_s(v1, v2, v3, v4)
				mesh1.name = "wall";
				mesh1.castShadow = true; //
				mesh1.receiveShadow = true;
				//console.log("wall");
				//mesh1.rotation.x = 1.57 // (1.57 + roofTiltAngle); //-1.34//-Math.PI / 2;

				//mesh.add(mesh1);
				houseObject3D.add(mesh1);
				// houseObject3D_s.add(mesh1);

				let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
				edges.material.linewidth = 2;
				mesh1.add(edges);
			}

			//console.log("parapetHeight" + parapetHeight)
			if (parapetHeight != 0) {
				let object = mesh;
				object.updateMatrixWorld();
				let newUpdatedArray = [];
				//r120
				/*
				for (let j = 0; j < object.geometry.vertices.length; j++) {
					let vector = object.geometry.vertices[j].clone();
					vector.applyMatrix4(object.matrixWorld);
					newUpdatedArray.push(vector)
				}*/

				//r128
				for (let j = 0; j < object.geometry.parameters.shapes.curves.length; j++) {
					let vector =  new THREE.Vector3(object.geometry.parameters.shapes.curves[j].v1.x, object.geometry.parameters.shapes.curves[j].v1.y , 0.0 )//object.geometry.vertices[j].clone();
					vector.applyMatrix4(object.matrixWorld);
					newUpdatedArray.push(vector)
				}

				for (i = 0; i < newUpdatedArray.length ; i++) {
					//console.log(i)
					//console.log(newUpdatedArray.length)
					let v1 = new THREE.Vector3(newUpdatedArray[i].x, newUpdatedArray[i].y, newUpdatedArray[i].z);
					let v2 = new THREE.Vector3(newUpdatedArray[i].x, newUpdatedArray[i].y , roofHeight + parapetHeight);
					let v3 ;
					let v4;
					if (i == newUpdatedArray.length - 1) {
						 v3 = new THREE.Vector3(newUpdatedArray[0].x, newUpdatedArray[0].y, newUpdatedArray[i].z + parapetHeight);
						 v4 = new THREE.Vector3(newUpdatedArray[0].x, newUpdatedArray[0].y, newUpdatedArray[0].z);
					}else{
					 	v3 = new THREE.Vector3(newUpdatedArray[i+1].x, newUpdatedArray[i+1].y, newUpdatedArray[i].z + parapetHeight);
					 	v4 = new THREE.Vector3(newUpdatedArray[i+1].x, newUpdatedArray[i+1].y, newUpdatedArray[i+1].z);
					}

					let v1_array = [v1.x, v1.y, v1.z];
					let v2_array = [v2.x, v2.y, v2.z];
					let v3_array = [v3.x, v3.y, v3.z];
					let v4_array = [v4.x, v4.y, v4.z];

					let finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array]
					let mesh1 = drawPolygon_s(v1, v2, v3, v4)
					mesh1.name = "parafet";
					mesh1.castShadow = true; //
					mesh1.receiveShadow = true;
					//console.log("wall");
					//mesh1.rotation.x = 1.57 // (1.57 + roofTiltAngle); //-1.34//-Math.PI / 2;

					//mesh.add(mesh1);
					houseObject3D.add(mesh1);
					// houseObject3D_s.add(mesh1);

					let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
					edges.material.linewidth = 2;
					mesh1.add(edges);
				}

			}

			//return mesh;
			return houseObject3D;
			// return houseObject3D_s;

		//} else {
			//console.log("Problem with data");
		//}
	//} else {
		//console.log("Problem with data");
	//}
}

function createKeepOut_s(keepouts, outerMesh, height){
		let solarObject = keepouts;

		for (let i = 0; i <  1 /* solarObject.length*/; i++) {
			let solarObject3D = new THREE.Object3D();
			solarObject.name = "keepout"
			solarArray_s = [];
			let solarData = solarObject.path;
			for (let j = 0; j < solarData.length-1; j++) {
				let data = solarData[j];
				solarArray_s.push(+(data.x));
				solarArray_s.push(+(data.y));
			}
			for (let k = 0; k < (solarArray_s.length / 2); k++) {
				solarArray_s[k * 2 + 0] = solarArray_s[k * 2 + 0] - referncePoint_s.x;
				solarArray_s[k * 2 + 1] = solarArray_s[k * 2 + 1] - referncePoint_s.y;
			}
			if (i == 0){
				diff_solar = solarArray_s[3] - solarArray_s[1];
			}

			var m = scene_s.getObjectByName("LeftMesh");
			if (m != undefined) {
				//var vectorArray = [0, 0, 59.99999997019768, 0, 142, 91, 88, 91, 54.99999997019768, 61, 24, 91, -86, 92, -78, 74, -89, 78, 0, 0]

				const polygon = new THREE.Shape();
				polygon.currentPoint.x = solarArray_s[0];
				polygon.currentPoint.y = -solarArray_s[1];
				for (let i = 0; i < ((solarArray_s.length / 2)); i++) {
					if (i == 1) {
						//polygon.moveTo( vectorArray[i*2 + 0], vectorArray[i*2 + 1] );
						polygon.lineTo(solarArray_s[i * 2 + 0], -solarArray_s[i * 2 + 1]);
					} else {
						polygon.lineTo(solarArray_s[i * 2 + 0], -solarArray_s[i * 2 + 1]);
					}
				}
				const geometry = new THREE.ShapeGeometry(polygon);
				const material_Lroof = new THREE.MeshPhysicalMaterial({ color: 0x808080, side: THREE.DoubleSide });
				material_Lroof.transparent = true;
				material_Lroof.opacity = 1.0 //0.7;
				const mesh = new THREE.Mesh(geometry, material_Lroof);
				mesh.name = "keepoutMesh";
				mesh.rotation.x =  0; //solarPanelAngle //solarAngle ; //0.32 //0.16//-Math.PI / 2;
				mesh.castShadow = true;
				var mesh2 = mesh.clone();

				//m.add(mesh);
				solarObject3D.add(mesh);
				solarObject3D.add(mesh2);
				//m.add(solarObject3D)
				outerMesh.add(solarObject3D);
				mesh.position.z = height //-12.0;    //Change position
				mesh2.position.z = 0
				mesh2.name = "bottomkeepoutMesh"
				mesh2.castShadow = true;
				//let edges = new THREE.EdgesHelper(mesh, 0xff0000);
				//edges.material.linewidth = 2;
				//mesh.add(edges);
				//let edges2 = new THREE.EdgesHelper(mesh2, 0xff0000);
				//edges2.material.linewidth = 2;
				//mesh2.add(edges2);
				let object =  mesh //mesh2;
				let y = 0;
				object.updateMatrixWorld();
				let updatedArray = [];

				//r128
			for (let j = 0; j < object.geometry.parameters.shapes.curves.length; j++) {
				let vector =  new THREE.Vector3(object.geometry.parameters.shapes.curves[j].v1.x, object.geometry.parameters.shapes.curves[j].v1.y , 0.0 )//object.geometry.vertices[j].clone();
				vector.applyMatrix4(object.matrixWorld);
				updatedArray.push(vector)
			}

			//r120
			/*
			for (let j = 0; j < object.geometry.vertices.length; j++) {
					let vector = object.geometry.vertices[j].clone();
					vector.applyMatrix4(object.matrixWorld);
					updatedArray.push(vector)
				}
			*/
				updatedArray.push(updatedArray[0].clone());
				for (i = 0; i <  updatedArray.length - 1 ; i++) {
					let v1 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y, updatedArray[i].z);
					let v2 = new THREE.Vector3(updatedArray[i].x, updatedArray[i].y , 0);
					let v3 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, 0);
					let v4 = new THREE.Vector3(updatedArray[i+1].x, updatedArray[i+1].y, updatedArray[i+1].z);
					let v1_array = [v1.x, v1.y, v1.z];
					let v2_array = [v2.x, v2.y, v2.z];
					let v3_array = [v3.x, v3.y, v3.z];
					let v4_array = [v4.x, v4.y, v4.z];

					let finalArray = [v1_array, v2_array, v3_array, v4_array, v1_array]
					let mesh1 = drawPolygon_s(v1, v2, v3, v4)
					mesh1.name = "wall";
					mesh1.castShadow = true; //
					mesh1.receiveShadow = true;
					//console.log("wall");
					//mesh1.rotation.x = 1.57 // (1.57 + roofTiltAngle); //-1.34//-Math.PI / 2;

					//mesh.add(mesh1);
					solarObject3D.add(mesh1);

					let edges = new THREE.EdgesHelper(mesh1, 0xffffff);
					edges.material.linewidth = 0.1;
					mesh1.add(edges);
				}

			}
	}
}

function createSolarFromArray_s(jsonObject, outerMesh ,solarPanelAngle) {

	let solarObject = jsonObject

	for (let i = 0; i < solarObject.length ; i++) {
		let solarObject3D = new THREE.Object3D();
		solarObject.name = "solarobject"
		solarArray_s = [];
		let solarData = solarObject[i].coords;
		for (let j = 0; j < solarData.length; j++) {
			let data = solarData[j];
			solarArray_s.push(+(data.x));
			solarArray_s.push(+(data.y));
		}
		for (let k = 0; k < (solarArray_s.length / 2); k++) {
			solarArray_s[k * 2 + 0] = solarArray_s[k * 2 + 0] - referncePoint_s.x;
			solarArray_s[k * 2 + 1] = solarArray_s[k * 2 + 1] - referncePoint_s.y;
		}
		var m = scene_s.getObjectByName("LeftMesh");
		if (m != undefined) {
			//var vectorArray = [0, 0, 59.99999997019768, 0, 142, 91, 88, 91, 54.99999997019768, 61, 24, 91, -86, 92, -78, 74, -89, 78, 0, 0]

			const polygon = new THREE.Shape();
			polygon.currentPoint.x = solarArray_s[0];
			polygon.currentPoint.y = -solarArray_s[1];

			for (let j = 0; j < ((solarArray_s.length / 2)); j++) {
				if (j == 1) {
					//polygon.moveTo( vectorArray[i*2 + 0], vectorArray[i*2 + 1] );
					//polygon.lineTo(solarArray[i * 2 + 0], -solarArray[i * 2 + 1]);
					polygon.moveTo(solarArray_s[j * 2 + 0], -solarArray_s[j * 2 + 1]);
				} else {
					polygon.lineTo(solarArray_s[j * 2 + 0], -solarArray_s[j * 2 + 1]);
				}
			}
			const geometry = new THREE.ShapeGeometry(polygon); //0x09143C
			const material_Lroof = new THREE.MeshPhysicalMaterial({ color: 0x474490 , side: THREE.DoubleSide });
			material_Lroof.transparent = true;
			material_Lroof.opacity = 1.0 //0.7;
			//material_Lroof.map = loader.load('image/pannel.JPG');
			const mesh = new THREE.Mesh(geometry, material_Lroof);
			mesh.name = "Solar";
			mesh.castShadow = true;
			mesh.receiveShadow = true
			var mesh2 = mesh.clone();
			solarObject3D.add(mesh);
			solarObject3D.add(mesh2);
			outerMesh.add(solarObject3D);
			mesh.position.z = 2.0;
			mesh2.position.z = 0.5;
			mesh2.name = "bottomSolar"
			mesh2.castShadow = true;
			mesh.receiveShadow = true

			// Calculating roof rotation axis vector
			let roofRotationAxisVector = new THREE.Vector3( solarArray_s[0] - solarArray_s[6], -(solarArray_s[1] - solarArray_s[7]), 0 );
			roofRotationAxisVector.normalize ()


			// Calculating roof Rotation Point through which rotation axis passes
			//let roofPoint = new THREE.Vector3( solarArray[0], -solarArray[1],  0 ); //  vectorArray[2] );
			let roofPoint = new THREE.Vector3( solarArray_s[2], -solarArray_s[3],  0 ); //  vectorArray[2] );
			rotateAroundWorldAxis_s(solarObject3D,roofPoint,roofRotationAxisVector, -solarPanelAngle)



			var arrowLength = 5;
			var arrowColor = 0x00ff00;


			let edges = new THREE.EdgesHelper(mesh, 0xffffff);
			edges.material.linewidth = 2;
			mesh.add(edges);
			let edges2 = new THREE.EdgesHelper(mesh2, 0xffffff);
			edges2.material.linewidth = 2;
			mesh2.add(edges2);
			let object = mesh2;
			let y = 0;
			object.updateMatrixWorld();
			var updatedArray = [];
			//r120
			/*
			for (let j = 0; j < object.geometry.vertices.length; j++) {
				let vector = object.geometry.vertices[j].clone();
				vector.applyMatrix4(object.matrixWorld);

				if (j == 1){
					y =vector.z
				}
			}*/

			//r128
			for (let j = 0; j < object.geometry.parameters.shapes.curves.length; j++) {
				let vector =  new THREE.Vector3(object.geometry.parameters.shapes.curves[j].v1.x, object.geometry.parameters.shapes.curves[j].v1.y , 0.0 )//object.geometry.vertices[j].clone();
				vector.applyMatrix4(object.matrixWorld);
				if (j == 1){
					y =vector.z
				}
			}

			let sideMaterial = new THREE.MeshStandardMaterial({ color: 0x808080, side: THREE.DoubleSide });
			let o = mesh;
			let o1 = mesh2;
			o.updateMatrixWorld();
			o1.updateMatrixWorld();
			let vector1 =  new THREE.Vector3(o.geometry.parameters.shapes.curves[2].v2.x,o.geometry.parameters.shapes.curves[2].v2.y,0)                      //o.geometry.vertices[0].clone();
			vector1.applyMatrix4(o.matrixWorld);
			let vector2 = new THREE.Vector3(o.geometry.parameters.shapes.curves[2].v1.x,o.geometry.parameters.shapes.curves[2].v1.y,0)    //o.geometry.vertices[1].clone();
			vector2.applyMatrix4(o.matrixWorld);
			let vector3 = new THREE.Vector3(o.geometry.parameters.shapes.curves[2].v2.x,o.geometry.parameters.shapes.curves[2].v2.y,0)  //o1.geometry.vertices[0].clone();
			vector3.applyMatrix4(o1.matrixWorld);
			let vector4 = new THREE.Vector3(o1.geometry.parameters.shapes.curves[2].v1.x,o1.geometry.parameters.shapes.curves[2].v1.y,0)  //o1.geometry.vertices[1].clone();
			vector4.applyMatrix4(o1.matrixWorld);
			let wall1 = drawPolygon_s(vector1, vector3, vector4, vector2)
			wall1.name = "solarwall";
			wall1.material = sideMaterial;
			edges = new THREE.EdgesHelper(wall1, 0xffffff);
			edges.material.linewidth = 2;
			wall1.add(edges);
			solarObject3D.add(wall1)


			vector1 =  new THREE.Vector3(o.geometry.parameters.shapes.curves[1].v2.x,o.geometry.parameters.shapes.curves[1].v2.y,0)                      //o.geometry.vertices[0].clone();
			vector1.applyMatrix4(o.matrixWorld);
			vector2 = new THREE.Vector3(o.geometry.parameters.shapes.curves[1].v1.x,o.geometry.parameters.shapes.curves[1].v1.y,0)    //o.geometry.vertices[1].clone();
			vector2.applyMatrix4(o.matrixWorld);
			vector3 = new THREE.Vector3(o.geometry.parameters.shapes.curves[1].v2.x,o.geometry.parameters.shapes.curves[1].v2.y,0)  //o1.geometry.vertices[0].clone();
			vector3.applyMatrix4(o1.matrixWorld);
			vector4 = new THREE.Vector3(o1.geometry.parameters.shapes.curves[1].v1.x,o1.geometry.parameters.shapes.curves[1].v1.y,0)  //o1.geometry.vertices[1].clone();
			vector4.applyMatrix4(o1.matrixWorld);
			let wall2 = drawPolygon_s(vector1, vector3, vector4, vector2)
			wall2.name = "solarwall";
			wall2.material = sideMaterial;
			edges = new THREE.EdgesHelper(wall2, 0xffffff);
			edges.material.linewidth = 2;
			wall2.add(edges);
			solarObject3D.add(wall2)

			vector1 =  new THREE.Vector3(o.geometry.parameters.shapes.curves[1].v1.x,o.geometry.parameters.shapes.curves[1].v1.y,0)                      //o.geometry.vertices[0].clone();
			vector1.applyMatrix4(o.matrixWorld);
			vector2 = new THREE.Vector3(o.geometry.parameters.shapes.curves[0].v1.x,o.geometry.parameters.shapes.curves[0].v1.y,0)    //o.geometry.vertices[1].clone();
			vector2.applyMatrix4(o.matrixWorld);
			vector3 = new THREE.Vector3(o.geometry.parameters.shapes.curves[1].v1.x,o.geometry.parameters.shapes.curves[1].v1.y,0)  //o1.geometry.vertices[0].clone();
			vector3.applyMatrix4(o1.matrixWorld);
			vector4 = new THREE.Vector3(o1.geometry.parameters.shapes.curves[0].v1.x,o1.geometry.parameters.shapes.curves[0].v1.y,0)  //o1.geometry.vertices[1].clone();
			vector4.applyMatrix4(o1.matrixWorld);
			let wall3 = drawPolygon_s(vector1, vector3, vector4, vector2)
			wall3.name = "solarwall";
			wall3.material = sideMaterial;
			edges = new THREE.EdgesHelper(wall3, 0xffffff);
			edges.material.linewidth = 2;
			wall3.add(edges);
			solarObject3D.add(wall3)

			vector1 =  new THREE.Vector3(o.geometry.parameters.shapes.curves[0].v1.x,o.geometry.parameters.shapes.curves[0].v1.y,0)                      //o.geometry.vertices[0].clone();
			vector1.applyMatrix4(o.matrixWorld);
			vector2 = new THREE.Vector3(o.geometry.parameters.shapes.curves[2].v2.x,o.geometry.parameters.shapes.curves[2].v2.y,0)    //o.geometry.vertices[1].clone();
			vector2.applyMatrix4(o.matrixWorld);
			vector3 = new THREE.Vector3(o.geometry.parameters.shapes.curves[0].v1.x,o.geometry.parameters.shapes.curves[0].v1.y,0)  //o1.geometry.vertices[0].clone();
			vector3.applyMatrix4(o1.matrixWorld);
			vector4 = new THREE.Vector3(o1.geometry.parameters.shapes.curves[2].v2.x,o1.geometry.parameters.shapes.curves[2].v2.y,0)  //o1.geometry.vertices[1].clone();
			vector4.applyMatrix4(o1.matrixWorld);
			let wall4 = drawPolygon_s(vector1, vector3, vector4, vector2)
			wall4.name = "solarwall";
			wall4.material = sideMaterial;
			edges = new THREE.EdgesHelper(wall4, 0xffffff);
			edges.material.linewidth = 2;
			wall4.add(edges);
			solarObject3D.add(wall4)
		}
	}
}

function convertDegreeToRadian(degree) {
	return (degree * (Math.PI / 180));
}


function drawPolygon_s(v1, v2, v3, v4) {

	const geometry1 = new THREE.BufferGeometry();
	const vertices = new Float32Array( [
		v1.x , v1.y , v1.z ,
		v2.x , v2.y , v2.z ,
		v3.x , v3.y , v3.z ,
		v1.x , v1.y , v1.z ,
		v3.x , v3.y , v3.z ,
		v4.x , v4.y , v4.z ,

	] );


	// itemSize = 3 because there are 3 values (components) per vertex
	geometry1.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
	geometry1.computeVertexNormals();
	const material1 = new THREE.MeshPhysicalMaterial({ color: 0x808080, side: THREE.DoubleSide });
	const mesh = new THREE.Mesh( geometry1, material1 );
	//mesh.receiveShadow = true;
	//mesh.castShadow = true;
	return mesh;



	//Older Code
	/*
	var geom = new THREE.Geometry();
	geom.vertices.push(v1);
	geom.vertices.push(v2);
	geom.vertices.push(v3);

	geom.vertices.push(v4);

	geom.faces.push(new THREE.Face3(0, 1, 2));
	geom.faces.push(new THREE.Face3(0, 2, 3));
	geom.computeFaceNormals();

	const material = new THREE.MeshPhongMaterial({ color: 0x808080, side: THREE.DoubleSide });
	//material.transparent = true;
	//material.opacity = 1.0 //0.8;
	let object = new THREE.Mesh(geom, material);
	object.receiveShadow = true;
	object.castShadow = true;
	return object;
	*/
}


function animate_s() {
	//ExplodeObjectFunction();
	frameAnimation_s=requestAnimationFrame(animate_s);

	//if (helper_s != undefined){
	//	helper_s.update();
	//}

	//Uncomment
	/*
	if (directional_light.position.x < -400){
		directional_light.position.x = 400;
		directional_light.position.y = 0;
	}
	if (directional_light.position.x > 0){
		directional_light.position.x = directional_light.position.x - 0.5;
		directional_light.position.y = directional_light.position.y + 0.5;
	}else{
		directional_light.position.x = directional_light.position.x - 0.5;
		directional_light.position.y = directional_light.position.y - 0.5;
	}*/

	// required if controls.enableDamping or controls.autoRotate are set to true
	controls_s.update();
	//TWEEN.update();
	renderer_s.render(scene_s, camera_s);
}


function onWindowResize_s() {
	camera_s.aspect = window_s.innerWidth / window_s.innerHeight;
	camera_s.updateProjectionMatrix();
	renderer_s.setSize(window_s.innerWidth, window_s.innerHeight);

}






function renderHouse_s (jObject) {
	if(jObject && jObject.roofOutline.length>0){
	jsonObject_s=jObject;
	//referncePoint.x = jObject.roofOutline[0].path[0].x;
	//referncePoint.y = jObject.roofOutline[0].path[0].y;
	referncePoint_s.x = jsonObject_s.centerxy.x
	referncePoint_s.y = jsonObject_s.centerxy.y
    constant_s.LAT = jsonObject_s.lat
	constant_s.LON = jsonObject_s.lng
	constant_s.UTCOFFSET = jsonObject_s.offset
	let pixelPerFeet = jObject.pixelPerFeet;
	const d = new Date(2021, 11, 1, 16,00,00);   //21 December 2021  2PM Time
	calculateSolarPosition_s(d); //
	scene_s.add(directional_light_s);
	//sceneObject.position.x =  jObject.width/2;
	//sceneObject.position.y = -jObject.height/2;
	plane_s.geometry = new THREE.PlaneGeometry( jObject.width, jObject.height,16,16);

	//console.log

	//Perceptive Projection
	//camera.aspect = jObject.width/ jObject.height;
	//camera.updateProjectionMatrix();


	renderer_s.setSize(jObject.width, jObject.height);

	//Orthographic
	let w = jObject.width;
	let h = jObject.height;
	let viewSize = h;
	let aspectRatio = w / h;
	camera_s.left = (-aspectRatio * viewSize) / 2
	camera_s.right = (aspectRatio * viewSize) / 2
	camera_s.top = viewSize / 2
	camera_s.bottom = -viewSize / 2
	camera_s.updateProjectionMatrix();


	const geometry = new THREE.SphereGeometry( 15, 32, 16 );
	const material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
	const sphere = new THREE.Mesh( geometry, material );
	//sceneObject.position.x =  jObject.width/2;
	//sceneObject.position.y = -jObject.height/2;
	//sceneObject.add( sphere );

	//var texture = new THREE.Texture(jObject.image);
	//texture.needsUpdate = true;
	//var img = new Image()
	//img.src = jObject.image
	//var texture = new THREE.Texture();
	//texture.needsUpdate = true;
	//texture.image = img
	//plane_s.material.map = texture

	let firstMesh = undefined
	let secondMesh = undefined
	var roofArrayObject = [];
	for (let i=0; i < jObject.roofOutline.length ; i++){
		let roofHeight = jObject.roofOutline[i].roofHeight * pixelPerFeet;

		console.log(roofHeight)
		let tilt = jObject.roofOutline[i].tilt
		if (tilt == undefined) {
			tilt = 0;
		}
		let tiltInRadian = convertDegreeToRadian(tilt)
		//tiltInRadian = 0
		let parapetHeight = 0 ;
		if (jObject.roofOutline[i].parapetHeight != undefined ){
			parapetHeight = jObject.roofOutline[i].parapetHeight * pixelPerFeet
		}else{
			parapetHeight = 0
		}

		let mesh = createLeftRoofFromArray_s(jObject.roofOutline[i].path,roofHeight,tiltInRadian,0,parapetHeight);
		roofArrayObject.push(mesh)
		roofArrayObject.push(jObject.roofOutline[i].id)
	}
	for (let i=0; i < jObject.panelArray.length ; i++){
		for (let j=0; j < roofArrayObject.length ; j++){
			if (j%2 == 1){
				if(jObject.panelArray[i].roofId == roofArrayObject[j]){
					let mesh = roofArrayObject[j-1]
					//console.log(mesh)
					let tilt1 = jObject.panelArray[i].tilt
					if (tilt1 == undefined) {
						tilt1 = 0;
					}
					let tiltInRadian1 = convertDegreeToRadian(tilt1)  //0.45
					//createSolarFromArray_s(jObject.panelArray[i].panels, mesh.children[0] , tiltInRadian1);
				}
			}
		}
	}
	for (let i=0; i < jObject.keepouts.length ; i++){
		let keepoutHeight = jObject.keepouts[i].height * pixelPerFeet + jObject.keepouts[i].baseHeight * pixelPerFeet;
		if(jObject.keepouts[i].type==3){
			var center = jObject.keepouts[i].center;
			var radius = jObject.keepouts[i].radius *  pixelPerFeet;
			createTree_s(center.x, center.y ,radius, keepoutHeight)
		}else{
			createKeepOut_s(jObject.keepouts[i], sceneObject_s , keepoutHeight);
		}
	}

	//var roofTiltAngleInradian = convertDegreeToRadian(roofTiltAngle);
	//var solarPanelAngleInradian = convertDegreeToRadian(solarPanelAngle);
	//let mesh = createLeftRoofFromArray(jsonObject ,roofHeight,roofTiltAngleInradian ,solarPanelAngleInradian);
	//createSolarFromArray(jsonObject.panelArray[0].panels, mesh , solarPanelAngleInradian);
	//createKeepOut(jsonObject.keepouts[0], mesh);
	//let t = scene.getObjectByName("tree", true);
	//if (t == undefined) {
		//createTree(0,0,32,50);
	//}
}
}

function rotateAroundWorldAxis_s ( object3D , point, axis, angle ) {
    // rotate object around axis in world space (the axis passes through point)
    // axis is assumed to be normalizedcd
    // assumes object does not have a rotated parent

    var q = new THREE.Quaternion();

        q.setFromAxisAngle( axis, angle );

        object3D.applyQuaternion( q );

        object3D.position.sub( point );
        object3D.position.applyQuaternion( q );
        object3D.position.add( point );

    }

     function dispose3dObjects_s(){
		disposeScene(sceneObject_s);
		disposeScene(scene_s);
		while(scene_s.children.length > 0){
			scene_s.remove(scene_s.children[0]);
		}
		while(sceneObject_s.children.length > 0){
			sceneObject_s.remove(sceneObject_s.children[0]);
		}
		renderer_s.dispose();
	 }
    //   directional_light_s.dispose();
    //   plane_s.geometry.dispose();
    //   houseObject3D_s.children.forEach(mesh =>{
    //     mesh.geometry && mesh.geometry.dispose();
    //     mesh.materia && mesh.material.dispose();
    //   })
    //   scene_s.children.forEach(mesh =>{
    //     if(mesh.type == 'Object3d'){
    //       mesh.children.forEach(m =>{
    //         m.geometry && m.geometry.dispose();
    //         m.material && m.material.dispose();
    //       })
    //       mesh.remove();
    //     }else{
    //       mesh.geometry && mesh.geometry.dispose();
    //       mesh.material && mesh.material.dispose();
    //     }
    //   })
    //   sceneObject_s.children.forEach(mesh =>{
    //     mesh.geometry &&  mesh.geometry.dispose();
    //     mesh.material && mesh.material.dispose();
    //   })
    // }
function clearScene_s() {
	if (scene_s != undefined){
		if (scene_s.children[0] != undefined){
			scene_s.children[0].parent.remove(scene_s.children[0]);

		}
    init_s();
	}

}
function clearData_s(){
	//cancelAnimationFrame(frameAnimation);
		jsonObject_s='';
	}

function captureSnapshot(config){
	captureComplete=false;
	shadeData_s=[];
	scene_s.background = new THREE.Color( 0xffffff );
	controls_s.autoRotate = false;
	controls_s.target.set(0, 0, 0)
	camera_s.position.x = 0;
	camera_s.position.y = 600;
	camera_s.position.z = 0;
	camera_s.updateProjectionMatrix();
	scene_s.background = new THREE.Color( 0xffffff );
	let plane = scene_s.getObjectByName("Plane")
	plane_s.parent.remove(plane);
	//let b_plane = scene.getChildByName("B_Plane")
	//b_plane.parent.remove(plane);

	scene_s.traverse( function(child) {

		if (child.name == "Solar"){
			child.visible = false
		}

		if (child.name == "bottomSolar"){
			child.visible = false
		}

		if (child.name == "solarwall"){
			child.visible = false
		}

		if (child instanceof THREE.Mesh) {
		  child.material.color.r = 1.0;
		  child.material.color.g = 1.0;
		  child.material.color.b = 1.0;
		  }
		  if (child instanceof THREE.LineSegments){
			child.visible = false
			//console.log("Line segment")
		  }
		  });


	let light = scene_s.getObjectByName("Dir_light")
	light.parent.remove(light);

	const d = new Date(2021, 00, 1, 09 ,00,00);   //21 December 2021  2PM Time
	calculateSolarPosition_s(d); //
	scene_s.add(directional_light_s);
	var basetime=10;
	var capturecount=1;
	for (let i=0 ; i<12 ; i++){
		let month = i;
		//basetime +=100;capturecount+=1;

		snapImage1(basetime,"1_"+(i+1)+"_09AM", new Date(2021, month,  1, 09,00,00),month,config,null)
		//basetime +=100;capturecount+=1;
		snapImage1(basetime,"1_"+(i+1)+"_10AM", new Date(2021, month, 1, 10,00,00),month,config,null)
		//basetime +=100;;capturecount+=1;
		snapImage1(basetime,"1_"+(i+1)+"_11AM", new Date(2021, month, 1, 11,00,00),month,config,null)
		//basetime +=100;;capturecount+=1;
		snapImage1(basetime,"1_"+(i+1)+"_12PM", new Date(2021, month, 1, 12,00,00),month,config,null)
		//basetime +=100;;capturecount+=1;
		snapImage1(basetime,"1_"+(i+1)+"_01PM", new Date(2021, month, 1, 13,00,00),month,config,null)
		//basetime +=100;;capturecount+=1;
		snapImage1(basetime,"1_"+(i+1)+"_02PM", new Date(2021, month, 1, 14,00,00),month,config,null)
		//basetime +=100;;capturecount+=1;
		snapImage1(basetime,"1_"+(i+1)+"_03PM", new Date(2021, month, 1, 15,00,00),month,config,null)
		//basetime +=100;capturecount+=1;
		if(i==11){
			snapImage1(basetime,"1_"+(i+1)+"_04PM", new Date(2021, month, 1, 16,00,00),month,config,shadingComplete);
		}else{
			snapImage1(basetime,"1_"+(i+1)+"_04PM", new Date(2021, month, 1, 16,00,00),month,config,null);
		}

		//basetime+=100;;capturecount+=1;

	}
	execwork();
}
var work=[];
function snapImage1(delay, imageName, date, month, config,callback){
	const data = {a:delay,b:imageName,c:date,d:month,e:config,f:callback};
	work.push(data);
}
function execwork(){
	var data=work.splice(0,1)[0];
	//console.log("execwork:"+work.length);
	snapImage(data.a, data.b, data.c, data.d, data.e,data.f);
}
function shadingComplete(config){
	//console.log("shading complete event");
	config['width']=jsonObject_s.width;
	config['height']=jsonObject_s.height;
	shadeDataObj_s['config']=config;
	shadeDataObj_s['shadeImage']=shadeData_s;
	shadeDataObj_s['panelcoords']=jsonObject_s.panelArray;
	//light = scene.getChildByName("Dir_light")
	//light.parent.remove(light);
	captureComplete = true;
}

function snapImage(delay, imageName, date, month, config,callback){
	//console.log("delay"+delay);
	setTimeout(function(){
		console.log("snapImage:"+delay);
		let canvas = document.getElementById("artifactCanvas_shade");
		let dataURL = canvas.toDataURL('image/png');
		//const Ink = document.createElement('a'); Ink.href = dataURL; Ink.download= imageName; //Ink.click();
		light = scene_s.getObjectByName("Dir_light")
		light.parent.remove(light);
		const d = date // new Date(2021, 00, 1, 16,00,00);   //21 December 2021  2PM Time
		calculateSolarPosition_s(d); //
		scene_s.add(directional_light_s);

		month+=1;
		const img={'file':imageName,'data':dataURL,'month':month};
		shadeData_s.push(img);//console.log("done:"+Ink.download);
		if(callback){
			shadingComplete(config);
		}else{
			execwork();
		}
	 }, delay);
}
